import { VFC } from "react";

interface Props {
  className?: string;
  tableClassName?: string;
  children?: JSX.Element | JSX.Element[];
}

const StyledTable: VFC<Props> = (props: Props) => {
  return (
    <div className={props.className || ""}>
      <table className={`table-fixed w-full ${props.tableClassName || ""}`}>
        {props.children}
      </table>
    </div>
  );
};

export default StyledTable;
