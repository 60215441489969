import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const StepPageWrapper: VFC<Props> = (props: Props) => {
  return <main className="flex-grow overflow-auto">{props.children}</main>;
};

export default StepPageWrapper;
