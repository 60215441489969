import axios from "axios";
import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router";

export interface Auth {
  isAuth: boolean;
  organizationId: string;
  authInfo: LoginSuccessResponse;
  signin: (
    email: string,
    password: string,
    navigate: NavigateFunction,
    onSuccess: () => void,
    onError: () => void,
    path: string
  ) => Promise<boolean>;
  signout: (navigate: NavigateFunction) => boolean;
  apiError: () => boolean;
  loading: boolean;
}

export interface LoginSuccessResponse {
  accessToken: string;
  refreshToken: string;
}

const AuthContext = createContext<Auth | null>(null);

type Props = {
  children: JSX.Element;
};

export function ProvideAuth({ children }: Props) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth(): Auth {
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [authInfo, setAuthInfo] = useState<LoginSuccessResponse>({
    accessToken: "",
    refreshToken: "",
  });

  useEffect(() => {
    setLoading(true);
    const localAuthInfo: LoginSuccessResponse = JSON.parse(
      window.localStorage.getItem("authInfo")
    );
    if (localAuthInfo && localAuthInfo.accessToken) {
      setAuthInfo(localAuthInfo);
      setIsAuth(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (authInfo.accessToken !== "") {
      window.localStorage.setItem("authInfo", JSON.stringify(authInfo));
    }
  }, [authInfo]);

  const signin = async (
    email: string,
    password: string,
    navigate: NavigateFunction,
    onSuccess: () => void,
    onError: () => void,
    path: string
  ) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_DOMAIN_V2}/api/v2/login`, {
          email,
          password,
        })
        .then((res) => {
          setAuthInfo({
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
          setIsAuth(true);
          (window as any).gtag("event", "publisher_login_success", {
            event_category: "publisher_login",
            email: email,
          });
          console.log(t("hooks.login_success"));
          onSuccess();
          navigate(path);
          return true;
        });
    } catch (error) {
      if (error.message !== "") {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar(t("general.service_failed"), { variant: "error" });
      }
      onError();
      return false;
    }

    return false;
  };

  const signout = (navigate: NavigateFunction) => {
    setAuthInfo({ accessToken: "", refreshToken: "" });
    window.localStorage.removeItem("authInfo");
    setIsAuth(false);
    navigate("/signin");
    return true;
  };

  const apiError = () => {
    setAuthInfo({ accessToken: "", refreshToken: "" });
    window.localStorage.removeItem("authInfo");
    setIsAuth(false);
    window.location.href = "/signin";
    return true;
  };

  // Return the user object and auth methods
  return {
    isAuth,
    organizationId,
    authInfo,
    signin,
    signout,
    apiError,
    loading,
  };
}
