import { OutputData } from "@editorjs/editorjs";
import EditorJSHtml from "editorjs-html";
import { youtubeParser } from "src/utils/editorjs/renderYoutube";

export const createHtmlBody = (outputBody: OutputData, summary: string) => {
  const parser = EditorJSHtml({ youtubeEmbed: youtubeParser });
  const mainText = parser.parse(outputBody).reduce((x, y) => `${x}${y}`, " ");
  // mainTextのimgタグにstyle="width: 100%;"を追加する
  const mainTextWithWidth100 = mainText.replace(
    // eslint-disable-next-line no-useless-escape
    /\<img/g,
    '<img style="width: 100%;"'
  );
  const htmlBody = `${
    summary.length > 0 ? `<p>${summary}</p>` : ""
  }${mainTextWithWidth100}`;

  return htmlBody;
};
