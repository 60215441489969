/** @jsxImportSource @emotion/react */
import { OutputData } from "@editorjs/editorjs";
import { css } from "@mui/material";
import EditorJSHtml from "editorjs-html";
import { DateTime } from "luxon";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { BusinessCategory } from "src/entities/BusinessCategory";
import { youtubeParser } from "src/libs/editor/renderYoutube";
import { imageParser } from "src/utils/editorjs/imageParser";
import tw from "twin.macro";

interface Props {
  title?: string;
  publishedAt?: DateTime;
  publisherName?: string;
  summary?: string;
  thumbnail?: File;
  body?: OutputData;
  companyInfo?: string;
  mediaContact?: string;
  businessCategories?: Array<BusinessCategory>;
  thumbnailUrl?: string;
  thumbnailAsDefault?: boolean;
}

const ArticleDesktopConfirmPreview: VFC<Props> = (props: Props) => {
  const [t] = useTranslation();
  return (
    <div
      className="p-8 overflow-scroll rounded-lg"
      style={{
        background: "#ffffff",
        height: "fit-content",
        width: "100%",
        zIndex: 100,
      }}
    >
      {props.title ? (
        <h1 className="text-3xl font-bold mb-4">{props.title}</h1>
      ) : (
        <h1 className="text-3xl font-bold mb-4 text-neutral-400">
          {t("general.notitle")}
        </h1>
      )}
      <div css={detailMainCompanyName}>
        <div css={detailInfoWrapper}>
          {props.publisherName ? (
            <span css={publisherName}>{props.publisherName}</span>
          ) : null}
          {props.publishedAt ? (
            <time
              className="ml-4"
              dateTime={props.publishedAt.toISO()}
              css={detailDateTime}
            >
              {props.publishedAt.toFormat("MMMM dd yyyy")}
            </time>
          ) : null}
        </div>
      </div>
      {props.summary.length > 0 ? (
        <div css={summaryWrapper}>{props.summary}</div>
      ) : null}
      {props.thumbnail ? (
        <img
          key={props.thumbnail.name}
          src={URL.createObjectURL(props.thumbnail)}
          alt="preview"
          className="pb-8"
          // style={{ maxHeight: 240 }}
        />
      ) : props.thumbnailAsDefault && props.thumbnailUrl.length > 0 ? (
        <img
          key="default_image"
          src={props.thumbnailUrl}
          alt="preview"
          className="pb-8"
          // style={{ maxHeight: 240 }}
        />
      ) : null}
      {props.body && props.body.blocks.length > 0 ? (
        <div className="pb-8">
          <ArticleViewer body={props.body} />
        </div>
      ) : (
        <div className="text-neutral-400 pb-8">{t("general.nobody")}</div>
      )}
      {props.companyInfo?.length > 0 ? (
        <div>
          <div css={otherInfoTitle}>{`About ${props.publisherName}`}</div>
          <div css={otherInfoWrapper}>{props.companyInfo}</div>
        </div>
      ) : null}
      {props.mediaContact?.length > 0 ? (
        <div>
          <div css={otherInfoTitle}>{t("article.contact")}</div>
          <div css={otherInfoWrapper}>{props.mediaContact}</div>
        </div>
      ) : null}
      {props.businessCategories?.length > 0 ? (
        <div>
          <hr className="solid" />
          <div css={categoriesContent}>
            <div css={categoriesTag}>{t("article.category")}</div>
            <div css={categories}>
              {props.businessCategories.map((businessCategory) => {
                return (
                  <span css={tag} key={businessCategory.id}>
                    {businessCategory.name}
                  </span>
                );
              })}
            </div>
          </div>
          <hr className="solid" />
        </div>
      ) : null}
    </div>
  );
};

type ArticleViewerProps = {
  body: OutputData;
};

const ArticleViewer = ({ body }: ArticleViewerProps): JSX.Element => {
  if (body === undefined) {
    return <div />;
  }

  const parser = EditorJSHtml({
    youtubeEmbed: youtubeParser,
    image: imageParser,
  });
  const mainText = parser.parse(body).reduce((x, y) => `${x}${y}`);

  return (
    // eslint-disable-next-line react/no-danger
    <div css={detailBody} dangerouslySetInnerHTML={{ __html: mainText }} />
  );
};

const detailBody = css`
  color: #2d2d2d;
  h1 {
    font-size: xx-large;
    font-weight: bold;
  }
  h2 {
    font-size: 26px;
    font-weight: bold;
    padding-top: 16px;
    padding-bottom: 4px;
    line-height: 1.5;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 2px;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  figure {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  figcaption {
    font-size: 12px;
    margin-top: 8px;
    color: #707070;
    font-style: italic;
  }
  img {
    object-fit: cover;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    list-style: disc;
  }
  p {
    letter-spacing: 0.01rem;
    margin-bottom: 16px;
    overflow-wrap: anywhere;
    color: #707070;
    line-height: 1.7;
    @media (min-width: 1024px) {
      line-height: 1.8;
      font-size: 18px;
    }
  }
  a {
    text-decoration: underline;
    color: #0045ff;
  }
`;

const detailInfoWrapper = css`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const publisherName = css`
  font-weight: 700;
  color: #0045ff;
  margin-bottom: 12px;
  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
`;

const detailDateTime = css`
  color: #2d2d2d;
  font-size: 14px;
  margin-bottom: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    margin-bottom: 0px;
  }
`;

const detailMainCompanyName = css`
  ${tw`mt-4 flex flex-col items-start md:flex-row md:items-center`}
  color: #0045ff;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const summaryWrapper = css`
  margin-bottom: 32px;
  white-space: pre-wrap;
  color: #707070;
  line-height: 1.7;
  @media (min-width: 1024px) {
    line-height: 1.8;
    font-size: 18px;
  }
`;

const categoriesContent = css`
  display: flex;
  padding: 18px 0px;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 36px 0px;
  }
`;

const categoriesTag = css`
  font-weight: 700;
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    margin-right: 36px;
    margin-bottom: 0px;
  }
`;

const categories = css`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const tag = css`
  ${tw`sm:mr-6 mb-3 sm:mb-0 underline`}
  color: #0045FF;
`;

const otherInfoTitle = css`
  font-weight: 700;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const otherInfoWrapper = css`
  margin-bottom: 32px;
  white-space: pre-wrap;
  color: #707070;
  line-height: 1.7;
  @media (min-width: 1024px) {
    line-height: 1.8;
    font-size: 18px;
  }
`;

export default ArticleDesktopConfirmPreview;
