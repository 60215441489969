import HelpIcon from "@mui/icons-material/Help";
import { IconButton } from "@mui/material";
import { VFC } from "react";

interface Props {
  title: string;
  isHelp?: boolean;
  onClickHelp?: () => void;
}

const PageHeader: VFC<Props> = (props: Props) => {
  return (
    <div className="font-bold text-2xl flex pt-8 pb-2 items-center">
      {props.title}
      {props.isHelp ? (
        <IconButton onClick={props.onClickHelp} className="ml-1">
          <HelpIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export default PageHeader;
