/** @jsxImportSource @emotion/react */
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { VFC } from "react";

type variant = "primary" | "outlined" | "alert";
type Size = "small" | "medium" | "large";

interface Props {
  text: string;
  variant?: variant;
  disabled?: boolean;
  isFullWidth?: boolean;
  size?: Size;
  onClick?: () => void;
  openInNewTab?: boolean;
  className?: string;
  id?: string;
}

/**
 * Common button component
 *
 * @param text button text
 * @param id id
 * @param variant kind of button
 * @param disabled disabled flag
 * @param isFullWidth if you need 100% width, use this flag
 * @param size button size
 * @param onClick on click function
 * @param openInNewTab if you need open in new tab, use this flag
 * @returns
 */
const Button: VFC<Props> = (props: Props) => {
  let className: string;

  if (props.variant === "primary") {
    className = `flex justify-center items-center content-center rounded-md bg-primary hover:bg-button-hover disabled:bg-opacity-50 disabled:hover:bg-primary disabled:hover:bg-opacity-50 text-white ${props.className}`;
  } else if (props.variant === "outlined") {
    className = `flex justify-center items-center content-center rounded-md border-solid border-2 border-primary bg-white hover:border-button-hover hover:text-button-hover disabled:border-opacity-50 disabled:hover:border-primary disabled:text-primary disabled:hover:border-opacity-50 disabled:text-opacity-50 disabled:hover:text-opacity-50 text-primary ${props.className}`;
  } else if (props.variant === "alert") {
    className = `flex justify-center items-center content-center rounded-md hover:bg-alert-hover disabled:bg-opacity-50 disabled:hover:bg-alert disabled:hover:bg-opacity-50 text-red-500 ${props.className}`;
  } else {
    className = `flex justify-center items-center content-center rounded-md bg-primary hover:bg-button-hover disabled:bg-opacity-50 disabled:hover:bg-primary disabled:hover:bg-opacity-50 text-white ${props.className}`;
  }

  return (
    <button
      className={className}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{
        width: props.isFullWidth ? "100%" : undefined,
        border: `0.5px solid ${getBaseColor(props.variant)}`,
        padding: getPadding(props.size),
        fontSize: getFontSize(props.size),
        fontWeight: 600,
        pointerEvents: props.disabled ? "none" : undefined,
      }}
      id={props.id}
    >
      {props.openInNewTab && <OpenInNewIcon className="mr-2" />}
      {props.text}
    </button>
  );
};

/**
 * Return font size
 */
const getFontSize = (size?: Size) => {
  switch (size) {
    case "small":
      return 12;
    case "medium":
      return 16;
    case "large":
      return 20;
    default:
      return 16;
  }
};

/**
 * Return padding
 */
const getPadding = (size?: Size) => {
  switch (size) {
    case "small":
      return "4px 12px";
    case "medium":
      return "8px 24px";
    case "large":
      return "12px 32px";
    default:
      return "8px 16px";
  }
};

/**
 * Get base color
 *
 * @param variant
 * @returns
 */
// eslint-disable-next-line no-shadow
const getBaseColor = (variant?: variant) => {
  switch (variant) {
    case "primary":
      return "#0045FF";
    case "outlined":
      return "#0045FF";
    case "alert":
      return "#FF0000";
    default:
      return "#0045FF";
  }
};

export default Button;
