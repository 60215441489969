import { ProviderContext, SnackbarProvider } from "notistack";
import { createContext, createRef, VFC } from "react";
import { ProvideAuth } from "../hooks/useAuth";

import Root from "./Root";

export const errorContext = createContext(createRef<ProviderContext>());

const App: VFC = () => {
  const errorRef = createRef<ProviderContext>();

  return (
    <errorContext.Provider value={errorRef}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        autoHideDuration={3000}
      >
        <ProvideAuth>
          <Root />
        </ProvideAuth>
      </SnackbarProvider>
    </errorContext.Provider>
  );
};

export default App;
