/** @jsxImportSource @emotion/react */
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { Backdrop, CircularProgress, Dialog, IconButton } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CountryPath } from "src/entities/CountryPath";
import { GetMetaPriceResponse } from "src/entities/GetMetaPriceResponse";
import { Auth } from "src/hooks/useAuth";
import { useFetch } from "src/hooks/useFetch";
import { NumberUtils } from "src/utils/NumberUtils";
import getCountryName from "src/utils/getCountryName";
import Button from "../components/common/Button";
import { LightTooltip } from "../components/common/LightTooltip";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  auth: Auth;
  country: CountryPath;
  isPublisherInfoLoading: boolean;
  credits?: string;
  is_subscripted: boolean;
  defaultCountry?: string;
}

const CreditsmanagementPage: VFC<Props> = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Common parameters
   */
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Buying Credits
   */
  const [buyingCredits, setBuyingCredits] = useState(1);
  /**
   * Increment buying credits
   */
  const incrementBuyingCredits = () => {
    setBuyingCredits(buyingCredits + 1);
  };
  /**
   * Decrement buying credits
   */
  const decrementBuyingCredits = () => {
    if (buyingCredits > 1) {
      setBuyingCredits(buyingCredits - 1);
    }
  };

  /**
   * Post redirect credit purchase session
   */
  const redirectPurchaseCredits = async () => {
    handleBackdropOpen();
    await axios
      .request({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/payment/payment_session`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${props.auth.authInfo.accessToken}`,
          country: props.country,
        },
        data: {
          success_path: `credits_management?result=added_success&country=${props.country}`,
          cancel_path: `credits_management?country=${props.country}`,
          quantity: buyingCredits,
          country: props.country,
        },
      })
      .then((res) => {
        /**
         *
         * Open tab
         */
        const { url } = res.data;
        window.location.href = url;
        // const win = window.open(url, "_blank");
        // win?.focus();
      })
      .catch(() => {
        handleBackdropClose();
        enqueueSnackbar(t("error.login_again"), {
          variant: "error",
        });
      });
  };

  /**
   * Post redirect subscription purchase session
   */
  const redirectPurchaseSubscription = async () => {
    handleBackdropOpen();
    await axios
      .request({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/payment/subscription_session`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${props.auth.authInfo.accessToken}`,
          country: props.country,
        },
        data: {
          success_path: `credits_management?result=added_success&country=${props.country}`,
          cancel_path: `credits_management?country=${props.country}`,
          country: props.country,
        },
      })
      .then((res) => {
        /**
         * Open tab
         */
        const { url } = res.data;
        window.location.href = url;
        // const win = window.open(url, "_blank");
        // win?.focus();
      })
      .catch(() => {
        handleBackdropClose();
        enqueueSnackbar(t("error.login_again"), {
          variant: "error",
        });
      });
  };

  /**
   * Post redirect customer portal session
   */
  const redirectCustomerPortal = async () => {
    handleBackdropOpen();
    await axios
      .request({
        method: "POST",
        url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/payment/portal_session`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${props.auth.authInfo.accessToken}`,
          country: props.country,
        },
        data: {
          return_path: `credits_management?country=${props.country}`,
        },
      })
      .then((res) => {
        handleBackdropClose();
        /**
         * Open tab
         */
        const { url } = res.data;
        window.location.href = url;
        // const win = window.open(url, "_blank");
        // win?.focus();
      })
      .catch(() => {
        handleBackdropClose();
        enqueueSnackbar(t("error.login_again"), {
          variant: "error",
        });
      });
  };

  /**
   * Get credit meta info
   */
  const { data: metaPrice, isLoading: isMetaPriceLoading } =
    useFetch<GetMetaPriceResponse>({
      url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/prices?country=${props.country}`,
      skip: props.credits === undefined,
      // headers: {
      //   country: props.country,
      // },
      // params: {
      //   country: props.country,
      // },
    });

  /**
   * Dropdown settings
   */
  /**
   * Dropdown parameters
   */
  const [backdropOpen, setBackdropOpen] = useState(false);
  /**
   * Open backdrop
   */
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };
  /**
   * Close backdrop
   */
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  /**
   * modal parameters
   */
  const [modalOpen, setModalOpen] = useState(false);
  /**
   * Modal open
   */
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  /**
   * modal close
   */
  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const result = params.get("result");

    if (result === "added_success") {
      enqueueSnackbar(t("credits_management.purchase.success"), {
        variant: "success",
      });
      const param = searchParams.get("result");
      if (param) {
        searchParams.delete("result");
        setSearchParams(searchParams);
      }
    }
    if (result === "subscription_success") {
      enqueueSnackbar(t("credits_management.subscribe.success"), {
        variant: "success",
      });
      const param = searchParams.get("result");
      if (param) {
        searchParams.delete("result");
        setSearchParams(searchParams);
      }
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <PageWrapper>
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <div className="p-8">
            <div style={{ textAlign: "right" }}>
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </div>
            {isMetaPriceLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              <div>
                <div className="flex items-center">
                  <div className="border w-64 rounded-lg">
                    <div className="rounded-t-lg flex justify-center px-4 py-2 bg-primary text-white font-bold text-md">
                      {t("credits_management.pay_as_you_go")}
                    </div>
                    <div className="p-4">
                      <div className="flex items-center mb-4">
                        <button
                          className="border h-6 w-6 rounded flex items-center justify-center"
                          type="button"
                          onClick={decrementBuyingCredits}
                        >
                          <RemoveIcon style={{ width: 12 }} />
                        </button>
                        <div className="font-bold text-primary text-3xl mx-2">
                          {buyingCredits}
                        </div>
                        <button
                          className="border h-6 w-6 rounded flex items-center justify-center"
                          type="button"
                          onClick={incrementBuyingCredits}
                        >
                          <AddIcon style={{ width: 12 }} />
                        </button>
                        <div className="ml-2 font-bold">
                          {t("payment.press_post.credit")}
                        </div>
                      </div>
                      <div className="flex mb-4 items-end">
                        <div className="text text-base font-bold mr-4">
                          {t("general.total")}
                        </div>
                        <div className="text-primary text-xl font-bold">
                          {metaPrice &&
                            NumberUtils.getCurrency(metaPrice.currency)}
                          {metaPrice &&
                            NumberUtils.numberToFinancialDisplayForIndonesia(
                              buyingCredits * metaPrice.credit_price
                            )}
                        </div>
                      </div>
                      <Button
                        text={t("general.buy")}
                        className="mb-4"
                        isFullWidth
                        disabled={buyingCredits === 0}
                        onClick={redirectPurchaseCredits}
                        openInNewTab
                      />
                      <div className="flex">
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          className="text-blue-500"
                        />
                        <div>{t("credits_management.cost")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mx-4">{t("credits_management.cost2")}</div>
                  <div className="border w-64 rounded-lg">
                    <div className="rounded-t-lg flex justify-center px-4 py-2 bg-primary text-white font-bold text-md">
                      {t("general.subscribe")}
                      <LightTooltip
                        placement="top"
                        title={t("credits_management.cost3")}
                      >
                        <LightbulbOutlinedIcon fontSize="small" />
                      </LightTooltip>
                    </div>
                    <div className="p-4">
                      <div className="flex items-center mb-4 h-9">
                        <div className="text-primary font-bold text-2xl">
                          {t("general.unlimited")}
                        </div>
                        <div className="ml-2 font-bold">
                          {t("payment.press_post.credit")}
                        </div>
                      </div>
                      <div className="flex mb-4 items-end">
                        <div className="text text-base font-bold mr-4">
                          {t("credits_management.monthly")}
                        </div>
                        <div className="text-primary text-xl font-bold">
                          {metaPrice &&
                            NumberUtils.getCurrency(metaPrice.currency)}
                          {metaPrice &&
                            NumberUtils.numberToFinancialDisplayForIndonesia(
                              metaPrice.subscription_price
                            )}
                        </div>
                      </div>
                      <Button
                        text={t("credits_management.subscribe")}
                        className="mb-4"
                        isFullWidth
                        openInNewTab
                        onClick={redirectPurchaseSubscription}
                      />
                      <div className="flex">
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          className="text-blue-500"
                        />
                        <div>
                          {t("credits_management.unlimited_plan.maximum")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(props.defaultCountry === "IDN" ||
                  props.defaultCountry === "PHL") && (
                  <div className="mt-2">
                    {t("credits_management.contact")}
                    <a
                      target="_blank"
                      href="https://wa.me/+62859106603488"
                      rel="noreferrer"
                    >
                      +62 859106603488
                    </a>
                    .
                  </div>
                )}
              </div>
            )}
          </div>
        </Dialog>

        <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={backdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <PageHeader title={t("credits_management.page_header")} />
        {props.isPublisherInfoLoading ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <div>
              <div className="mb-4 text font-bold text-lg flex items-center">
                {`${t("credits_management.current_plan")}: ${
                  props.is_subscripted
                    ? t("payment.press_post.unlimited")
                    : t("credits_management.pay_as_you_go")
                }`}
                {props.is_subscripted && (
                  <div className="ml-2">
                    <LightTooltip
                      placement="right"
                      title={t("credits_management.balance.tooltip", {
                        credits: props.credits,
                      })}
                    >
                      <TipsAndUpdatesOutlinedIcon />
                    </LightTooltip>
                  </div>
                )}
              </div>
              <div className="p-4 border rounded-lg w-64 mb-8">
                <div className="flex items-end mb-4">
                  {props.is_subscripted ? (
                    <div className="font-bold text-primary text-3xl">
                      {t("payment.press_post.unlimited")}
                    </div>
                  ) : (
                    <>
                      <div className="text font-bold text-lg">
                        {getCountryName(props.country)} Credits
                      </div>
                      <div className="font-bold text-primary text-3xl ml-2">
                        {props.credits}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-end">
                  {props.is_subscripted ? (
                    <Button
                      text={t("credits_management.detail")}
                      variant="outlined"
                      openInNewTab
                      onClick={redirectCustomerPortal}
                    />
                  ) : (
                    <Button
                      text={t("credits_management.add")}
                      id="payment_modal_button"
                      variant="outlined"
                      onClick={() => {
                        setBuyingCredits(1);
                        handleModalOpen();
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="mb-12">
                <div className="text-xl font-bold mb-2">
                  {t("credits_management.billing.title")}
                </div>
                <div>
                  <div className="mb-1">
                    {t("credits_management.billing.description")}
                  </div>
                  <Button
                    text={t("general.setting")}
                    variant="outlined"
                    openInNewTab
                    onClick={redirectCustomerPortal}
                  />
                </div>
              </div>

              <div
                className="p-6 rounded-lg"
                style={{
                  background: "#DCE5FE",
                  width: "fit-content",
                }}
              >
                <div className="text-black text-lg font-semibold mb-2">
                  Price for {getCountryName(props.country)}
                </div>
                <div className="flex justify-start items-center">
                  <div className="border w-72 h-48 bg-white rounded-lg">
                    <div className="flex justify-center bg-primary text-white py-4 font-semibold text-sm rounded-t-lg">
                      {t("credits_management.pay_as_you_go")}
                    </div>
                    <div className="flex flex-col items-center pt-8 px-6">
                      <div className="font-semibold">
                        {t("credits_management.credit_eq_press_release")}
                      </div>
                      <div className="text-black font-semibold text-lg mb-4">
                        {metaPrice &&
                          NumberUtils.getCurrency(metaPrice.currency)}
                        {metaPrice &&
                          NumberUtils.numberToFinancialDisplayForIndonesia(
                            metaPrice.credit_price
                          )}
                      </div>
                      <div className="flex items-center pb-6 text-xs">
                        <CheckCircleOutlineIcon
                          className="mr-1"
                          fontSize="small"
                          style={{ color: "#3B82F6" }}
                        />
                        {t("credits_management.no_subscription_required")}
                      </div>
                    </div>
                  </div>
                  <div className="font-semibold mx-4">or</div>
                  <div className="border w-72 bg-white rounded-lg">
                    <div className="flex justify-center bg-primary text-white py-4 font-semibold text-sm rounded-t-lg">
                      {t("credits_management.subscription")}
                    </div>
                    <div className="flex flex-col items-center pt-8 px-6">
                      <div className="font-semibold">Unlimited</div>
                      <div className="text-black font-semibold text-lg mb-4">
                        {metaPrice &&
                          NumberUtils.getCurrency(metaPrice.currency)}
                        {metaPrice &&
                          NumberUtils.numberToFinancialDisplayForIndonesia(
                            metaPrice.subscription_price
                          )}
                        <span className="text-sm ml-1">/month</span>
                      </div>
                      <div className="pb-6 text-xs">
                        <div className="flex items-center mb-1">
                          <CheckCircleOutlineIcon
                            className="mr-1"
                            fontSize="small"
                            style={{ color: "#3B82F6" }}
                          />
                          {t("credits_management.unlimited_plan.maximum")}
                        </div>
                        <div className="flex items-center mb-1">
                          <CheckCircleOutlineIcon
                            className="mr-1"
                            fontSize="small"
                            style={{ color: "#3B82F6" }}
                          />
                          {t("credits_management.unlimited_plan.31st")}
                        </div>
                        <div className="flex items-center mb-1">
                          <CheckCircleOutlineIcon
                            className="mr-1"
                            fontSize="small"
                            style={{ color: "#3B82F6" }}
                          />
                          {t("credits_management.unlimited_plan.one_day")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {props.country === "IDN" && (
                <div
                  className="p-6 rounded-lg mt-4"
                  style={{
                    background: "#DCE5FE",
                    width: "fit-content",
                  }}
                >
                  <div className="text-black text-lg font-semibold mb-2">
                    Promo
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="border w-72 bg-white rounded-lg">
                      <div className="flex justify-center bg-primary text-white py-4 font-semibold text-sm rounded-t-lg">
                        Top Media Guarantee
                      </div>
                      <div className="flex flex-col items-center pt-8 px-6">
                        <div className="font-semibold">
                          1 Media mulai dari Rp2jt
                        </div>
                        <div className="text-black font-semibold text-lg">
                          Buy 5 & Get 10% Off
                        </div>
                        <div className="font-semibold text-xs mb-4">
                          Maks Rp1jt
                        </div>
                        <Button
                          text="Request"
                          className="mb-4"
                          isFullWidth
                          onClick={() => {
                            window.open(
                              "https://forms.gle/y1ADWjAPtNHs7p6X8",
                              "_blank"
                            );
                          }}
                          openInNewTab
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PageWrapper>
    </LocalizationProvider>
  );
};

export default CreditsmanagementPage;
