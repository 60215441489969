/** @jsxImportSource @emotion/react */
import { OutputData } from "@editorjs/editorjs";
import CloseIcon from "@mui/icons-material/Close";
import { css, Dialog, IconButton } from "@mui/material";
import EditorJSHtml from "editorjs-html";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { youtubeParser } from "src/libs/editor/renderYoutube";
import { imageParser } from "src/utils/editorjs/imageParser";

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  body?: OutputData;
  summary?: string;
  use_thumbnail_as_featured_image?: boolean;
  thumbnail_url?: string;
  company_info?: string;
  media_contact?: string;
  publisher_name?: string;
}

const ArticlePreview: VFC<Props> = (props: Props) => {
  const [t] = useTranslation();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth="md"
    >
      <div className="p-12">
        <div
          style={{
            marginTop: -24,
            marginRight: -24,
            textAlign: "right",
          }}
        >
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {props.title ? (
          <h1 className="text-4xl font-bold mb-4">{props.title}</h1>
        ) : (
          <h1 className="text-4xl font-bold mb-4 text-neutral-400">
            {t("general.notitle")}
          </h1>
        )}
        {props.summary?.length > 0 ? (
          <div css={summaryWrapper}>{props.summary}</div>
        ) : null}
        {props.use_thumbnail_as_featured_image ? (
          <img
            key={props.thumbnail_url}
            src={props.thumbnail_url}
            alt="preview"
            css={thumbnailWrapper}
          />
        ) : null}
        {props.body && props.body.blocks.length > 0 ? (
          <ArticleViewer body={props.body} />
        ) : (
          <div className="text-neutral-400">{t("general.nobody")}</div>
        )}
        {props.company_info?.length > 0 ? (
          <div>
            <div css={otherInfoTitle}>{`About ${props.publisher_name}`}</div>
            <div css={otherInfoWrapper}>{props.company_info}</div>
          </div>
        ) : null}
        {props.media_contact?.length > 0 ? (
          <div>
            <div css={otherInfoTitle}>{t("article.contact")}</div>
            <div css={otherInfoWrapper}>{props.media_contact}</div>
          </div>
        ) : null}
      </div>
    </Dialog>
  );
};

type ArticleViewerProps = {
  body: OutputData;
};

const ArticleViewer = ({ body }: ArticleViewerProps): JSX.Element => {
  if (body === undefined) {
    return <div />;
  }

  const parser = EditorJSHtml({
    youtubeEmbed: youtubeParser,
    image: imageParser,
  });
  const mainText = parser.parse(body).reduce((x, y) => `${x}${y}`);

  return (
    // eslint-disable-next-line react/no-danger
    <div css={detailBody} dangerouslySetInnerHTML={{ __html: mainText }} />
  );
};

const detailBody = css`
  color: #2d2d2d;
  h1 {
    font-size: xx-large;
    font-weight: bold;
  }
  h2 {
    font-size: 26px;
    font-weight: bold;
    padding-top: 16px;
    padding-bottom: 4px;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 2px;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  figure {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  figcaption {
    font-size: 12px;
    margin-top: 8px;
    color: #707070;
    font-style: italic;
  }
  img {
    object-fit: cover;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    list-style: disc;
  }
  p {
    letter-spacing: 0.01rem;
    margin-bottom: 16px;
    overflow-wrap: anywhere;
    color: #707070;
    line-height: 1.7;
    @media (min-width: 1024px) {
      line-height: 1.8;
      font-size: 18px;
    }
  }
  a {
    text-decoration: underline;
    color: #0045ff;
  }
`;

const summaryWrapper = css`
  margin-bottom: 32px;
  white-space: pre-wrap;
  color: #707070;
  line-height: 1.7;
  @media (min-width: 1024px) {
    line-height: 1.8;
    font-size: 18px;
  }
`;

const thumbnailWrapper = css`
  margin-bottom: 32px;
`;

const otherInfoTitle = css`
  font-weight: 700;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const otherInfoWrapper = css`
  margin-bottom: 32px;
  white-space: pre-wrap;
  color: #707070;
  line-height: 1.7;
  @media (min-width: 1024px) {
    line-height: 1.8;
    font-size: 18px;
  }
`;

export default ArticlePreview;
