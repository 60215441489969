export const youtubeParser = (block: any) => {
  const { url } = block.data;
  if (!url) {
    console.log("url is empty");
    return `<div></div>`;
  }
  // eslint-disable-next-line no-useless-escape
  // const youtubeId = url.match(/(?<=v=)[a-zA-Z0-9_]+(?=\&?)/);
  const srcUrl = getYoutubeUrl(url);
  return `<div style="width: 100%; height: 100%; padding: 16px;"><iframe style="width: 100%; aspect-ratio: 16 / 9;" src="${srcUrl}" allowfullscreen=true></iframe></div>`;
};

const getYoutubeUrl = (url: string) => {
  // urlに&t=が含まれている場合はその部分を削除する（以降全ての前処理として実行）
  const planeUrl = url.replace(/&t=.*/, "");

  // urlに/embed/が含まれている場合はそのまま返す
  if (planeUrl.match(/\/embed\//)) {
    return planeUrl;
  }
  // urlに/watch?v=が含まれている場合は/embed/に置換して返す
  if (planeUrl.match(/\/watch\?v=/)) {
    return planeUrl.replace(/\/watch\?v=/, "/embed/");
  }
  // youtu.be/が含まれている場合はembed/に置換して返す
  if (planeUrl.match(/youtu.be/)) {
    return planeUrl.replace(/youtu.be/, "www.youtube.com/embed");
  }
  // eslint-disable-next-line consistent-return
  return planeUrl;
};
