/** @jsxImportSource @emotion/react */
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { css } from "@mui/material";
import { useState, VFC } from "react";

type InputType = "text" | "password" | "tel";

interface Props {
  value: string | number;
  onChange: (value: string) => void;
  type: InputType;
  dataTestId?: string;
  multiline?: boolean;
  label?: string;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

/**
 * @param value a value of state
 * @param onChange a function of changing the value
 * @param type a type of input, "text", "password", "tel"
 * @param multiline multiline flag
 * @param label a label of input field
 * @param placeholder a text of inline input field
 * @param onKeyDown a function of key down
 * @param disabled disabled flag
 */
const InputField: VFC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const inputType =
    props.type !== "password" ? props.type : visible ? "text" : "password";

  return (
    // TODO: required flag
    <div className="flex flex-col">
      {props.label ? (
        <span className="text-text-main">{props.label}</span>
      ) : null}
      {props.multiline ? (
        <textarea
          data-testid={props.dataTestId}
          className="border border-solid text-text-main border-borderGray rounded-md w-100% p-4 text-base resize-none"
          value={props.value}
          disabled={props.disabled}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          placeholder={props.placeholder}
          rows={3}
        />
      ) : (
        <div className="relative">
          <input
            data-testid={props.dataTestId}
            className="border border-solid text-text-main border-borderGray rounded-md h-12 w-100% px-4"
            type={inputType}
            disabled={props.disabled}
            value={props.value}
            onChange={(e) => {
              if (props.type === "tel") {
                /* eslint no-unused-expressions: "off" */
                /^\d*$/g.test(e.target.value) && props.onChange(e.target.value);
              } else {
                props.onChange(e.target.value);
              }
            }}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
          />
          {props.type === "password" ? (
            <button
              type="button"
              onClick={() => {
                setVisible(!visible);
              }}
              css={eyeButton}
            >
              {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

const eyeButton = css`
  position: absolute;
  right: 12px;
  height: 48px;
  color: #6c6c6c;
`;

export default InputField;
