import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const StyledTableRow: VFC<Props> = (props: Props) => {
  return <tr className="border-b">{props.children}</tr>;
};

export default StyledTableRow;
