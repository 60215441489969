/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import { css, Dialog, DialogActions, IconButton } from "@mui/material";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import Button from "../common/Button";

interface Props {
  open: boolean;
  handleClose: () => void;
  href: string;
}

const EditPublisherInfoHelpModal: VFC<Props> = (props: Props) => {
  const [t] = useTranslation();
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <div className="p-4">
        <div style={{ textAlign: "right" }}>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="text-base whitespace-pre-line text-black pt-4 pb-4">
          {t("edit_press_release.publisher.helper.content")}
          <a
            href={props.href}
            className="font-bold underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("edit_press_release.publisher.check")}
          </a>
        </div>
        <DialogActions>
          <Button text={t("modal.edit_publisher_info_help.button.ok")} onClick={props.handleClose} />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default EditPublisherInfoHelpModal;
