import axios, { AxiosError, Method } from "axios";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./useAuth";

interface FetchRequest<T> {
  method?: Method;
  url: string;
  params?: Params;
  headers?: Headers;
  onStart?: () => void;
  onEnd?: () => void;
  onSuccess?: (data?: T) => void;
  onError?: (err: any) => void;
}

type Refetch<T> = ({ url, params }?: RefetchArgs<T>) => Promise<T | null>;

interface FetchResponse<T> {
  data?: T | null;
  exeDelete: Refetch<T>;
  error: AxiosError | null;
  hasError: boolean;
  isLoading: boolean;
}

interface Headers {
  [key: string]: any;
}

interface Params {
  [key: string]: any;
}

interface RefetchArgs<T> {
  url?: string;
  method?: Method;
  params?: Params;
  body?: Params;
  onStart?: () => void;
  onEnd?: () => void;
  onSuccess?: (data?: T) => void;
  onError?: (err: AxiosError) => void;
}

export function useDelete<T>({
  url,
  method = "delete",
  params,
  headers,
  onStart,
  onEnd,
  onError,
  onSuccess,
}: FetchRequest<T>): FetchResponse<T> {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [hasError, setHasError] = useState(false);

  const { authInfo } = useAuth();

  const memoizeUrl = useMemo(() => url, [url]);
  const exeDelete = useCallback<Refetch<T>>(
    // eslint-disable-next-line no-shadow
    async <T,>(args?: RefetchArgs<T>) => {
      if (onStart) {
        onStart?.();
      }

      try {
        setLoading(true);

        await axios
          .delete(memoizeUrl, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: `Bearer ${authInfo.accessToken}`,
              ...headers,
            },
          })
          .then((res) => {
            // eslint-disable-next-line no-shadow
            const { data } = res;
            setData(data);
            // eslint-disable-next-line no-unused-expressions
            args.onSuccess
              ? args.onSuccess(data)
              : onSuccess
              ? onSuccess()
              : null;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return data;
          });
      } catch (err) {
        if (err.message !== "") {
          enqueueSnackbar(err.message, { variant: 'error' });
        } else {
          enqueueSnackbar(t("general.service_failed"), { variant: "error" });
        }
        onError?.(err);
        setError(err as AxiosError);
        setHasError(true);
        return null;
      } finally {
        setLoading(false);
        onEnd?.();
      }

      return null;
    },
    [headers, memoizeUrl, method, onError, params]
  );

  const clear = useCallback(() => {
    setData(null);
    setLoading(false);
    setHasError(false);
    setError(null);
  }, []);

  useEffect(() => () => clear(), [clear]);

  return {
    data,
    exeDelete,
    error,
    hasError,
    isLoading,
  };
}
