import { CountryPath } from "src/entities/CountryPath";

const getCountryName = (countryPath: CountryPath) => {
  switch (countryPath) {
    case "IDN":
      return "Indonesia";
    case "SGP":
      return "Singapore";
    case "PHL":
      return "Philippines";
    case "MYS":
      return "Malaysia";
    case "THA":
      return "Thailand";
    case "VNM":
      return "Vietnam";
    default:
      return "Unknown";
  }
};

export default getCountryName;
