import { useSnackbar } from "notistack";
import { Fragment, useState, useEffect, VFC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CountryPath } from "src/entities/CountryPath";
import { PublisherInfoResponse } from "src/entities/PublisherInfoResponse";
import { UserInfoResponse } from "src/entities/UserInfoResponse";
import { useFetch } from "src/hooks/useFetch";
import { checkSubscripted } from "src/utils/checkSubscripted";
import getBrowserLanguage, {
  AvailLanguage,
} from "src/utils/getBrowserLanguage";
import { getCredit } from "src/utils/getCredit";
import { useAuth } from "../hooks/useAuth";
import DashboardLayout from "./layouts/DashboardLayout";
import SignInLayout from "./layouts/SignInLayout";
import AdditionalServicePage from "./pages/AdditionalServicePage";
import AnalyticsPage from "./pages/AnalyticsPage";
import CreateArticlePage from "./pages/CreateArticlePage";
import CreditsmanagementPage from "./pages/CreditsManagementPage";
import DashboardPage from "./pages/DashboardPage";
import EditArticlePage from "./pages/EditArticlePage";
import EditPublisherInfoPage from "./pages/EditPublisherInfoPage";
import PotentialPublicationsPage from "./pages/PotentialPublicationsPage";
import ReportPage from "./pages/ReportPage";
import SignInPage from "./pages/SignInPage";

const Root: VFC = () => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [path, setPath] = useState<string>(getPath());

  const url = decodeURIComponent(window.location.href);
  const urlParam = new URLSearchParams(url.split("?")[1]).get("country");
  const queryCountry = getCountry(urlParam);
  const [country, setCountry] = useState<CountryPath>(
    queryCountry === "" ? "IDN" : queryCountry
  );

  const [lang, setLang] = useState<AvailLanguage>(getBrowserLanguage());
  useEffect(() => {
    console.log(lang);
  }, [lang]);

  const { data: userInfo, isLoading: isUserInfoLoading } =
    useFetch<UserInfoResponse>({
      url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publisherMembers/me`,
      skip: !auth.isAuth,
      headers: {
        country: country,
      },
      onSuccess: (data) => {
        if (data === undefined) {
          enqueueSnackbar(
            "An unexpected error has occurred. Please sign in again.",
            {
              variant: "error",
            }
          );
          auth.apiError();
        }
      },
      onError: () => {
        enqueueSnackbar(
          "An unexpected error has occurred. Please sign in again.",
          {
            variant: "error",
          }
        );
        auth.apiError();
      },
    });

  const {
    data: publisherInfo,
    isLoading: isPublisherInfoLoading,
    refetch: refetchPublisherInfo,
  } = useFetch<PublisherInfoResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${userInfo?.publisher_id}`,
    headers: {
      country: country,
    },
    skip: !auth.isAuth || !userInfo || userInfo?.publisher_id === undefined,
    onSuccess: (data) => {
      if (queryCountry) {
        return;
      }
      if (
        data.default_country === "IDN" ||
        data.default_country === "SGP" ||
        data.default_country === "MYS" ||
        data.default_country === "PHL" ||
        data.default_country === "THA" ||
        data.default_country === "VNM"
      ) {
        if (
          window.location.pathname === "/dashboard" ||
          window.location.pathname === "/analytics" ||
          window.location.pathname === "/settings"
        ) {
          setCountry(data.default_country as CountryPath);
        }
      }
    },
    onError: () => {
      enqueueSnackbar(
        "An unexpected error has occurred. Please sign in again.",
        {
          variant: "error",
        }
      );
      auth.apiError();
    },
  });

  return auth.loading ? (
    <div>Loading...</div>
  ) : (
    <Router>
      <Routes>
        {auth?.isAuth ? (
          <>
            <Route
              path="/"
              element={
                <DashboardLayout
                  signout={auth.signout}
                  lang={lang}
                  country={country}
                  setCountry={setCountry}
                  setLang={setLang}
                  credits={getCredit(
                    publisherInfo?.publisher_credits,
                    country
                  ).toString()}
                  isPublisherInfoLoading={
                    isPublisherInfoLoading || isUserInfoLoading
                  }
                  is_subscripted={checkSubscripted(
                    publisherInfo?.subscripted_countries,
                    country
                  )}
                  setPath={setPath}
                />
              }
            >
              <Route
                path="article/:publisherId/:slug/:step"
                element={
                  <EditArticlePage
                    auth={auth}
                    country={country}
                    userInfo={userInfo}
                    publisherInfo={publisherInfo}
                    credits={getCredit(
                      publisherInfo?.publisher_credits,
                      country
                    )}
                    refetchPublisherInfo={refetchPublisherInfo}
                  />
                }
              />
              <Route
                path="analytics"
                element={
                  <AnalyticsPage
                    signout={auth.signout}
                    lang={lang}
                    userInfo={userInfo}
                    country={country}
                  />
                }
              />
              <Route
                path="settings"
                element={
                  <EditPublisherInfoPage auth={auth} country={country} />
                }
              />
              <Route
                path="potential"
                element={
                  <PotentialPublicationsPage
                    signout={auth.signout}
                    lang={lang}
                    country={country}
                    userInfo={userInfo}
                    isUserInfoLoading={isUserInfoLoading}
                  />
                }
              />
              <Route
                path="credits_management"
                element={
                  <CreditsmanagementPage
                    auth={auth}
                    country={country}
                    defaultCountry={publisherInfo?.default_country}
                    credits={getCredit(
                      publisherInfo?.publisher_credits,
                      country
                    ).toString()}
                    isPublisherInfoLoading={
                      isPublisherInfoLoading || isUserInfoLoading
                    }
                    is_subscripted={checkSubscripted(
                      publisherInfo?.subscripted_countries,
                      country
                    )}
                  />
                }
              />
              <Route
                path="report"
                element={
                  <ReportPage
                    signout={auth.signout}
                    lang={lang}
                    country={country}
                    userInfo={userInfo}
                    isUserInfoLoading={isUserInfoLoading}
                  />
                }
              />
              <Route
                path="article/new"
                element={
                  <CreateArticlePage
                    auth={auth}
                    country={country}
                    userInfo={userInfo}
                    publisherInfo={publisherInfo}
                    credits={getCredit(
                      publisherInfo?.publisher_credits,
                      country
                    )}
                    refetchPublisherInfo={refetchPublisherInfo}
                  />
                }
              />
              <Route
                path="additional_service"
                element={<AdditionalServicePage />}
              />
            </Route>
            <Route
              path="/"
              element={
                <DashboardLayout
                  signout={auth.signout}
                  lang={lang}
                  country={country}
                  setCountry={setCountry}
                  setLang={setLang}
                  credits={getCredit(
                    publisherInfo?.publisher_credits,
                    country
                  ).toString()}
                  isPublisherInfoLoading={
                    isPublisherInfoLoading || isUserInfoLoading
                  }
                  is_subscripted={checkSubscripted(
                    publisherInfo?.subscripted_countries,
                    country
                  )}
                />
              }
            >
              <Route
                path="dashboard"
                element={
                  <DashboardPage
                    signout={auth.signout}
                    lang={lang}
                    country={country}
                    userInfo={userInfo}
                    isUserInfoLoading={isUserInfoLoading}
                  />
                }
              />
            </Route>
            <Route path="*" element={<Navigate to={path} />} />
          </>
        ) : (
          <Route path="/" element={<SignInLayout />}>
            <Route path="/" element={<Navigate to="signin" />} />
            <Route
              path="signin"
              element={
                <SignInPage
                  signIn={auth?.signin}
                  signOut={auth?.signout}
                  lang={lang}
                  setPath={setPath}
                  path={path}
                />
              }
            />
            <Route path="*" element={<Navigate to="signin" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

/**
 * Return path
 */
const getPath = () => {
  const url = decodeURIComponent(window.location.href);
  const urlParams = new URLSearchParams(url.split("?")[1]);
  const result = urlParams.get("result");
  const country = urlParams.get("country");

  const urlObject = new URL(url);
  const { pathname } = urlObject;

  if (pathname === "/signin") {
    return "dashboard";
  }
  if (pathname === "/dashboard") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/article/new") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/article/:publisherId/:slug") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/analytics") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/settings") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/credits_management") {
    if (result && country)
      return `${pathname}?result=${result}&country=${country}`;
    if (result) return `${pathname}?result=${result}`;
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/report") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  if (pathname === "/additional_service") {
    if (country) return `${pathname}?country=${country}`;
    return pathname;
  }
  return "dashboard";
};

const getCountry = (country: string | CountryPath) => {
  if (
    country === "IDN" ||
    country === "SGP" ||
    country === "MYS" ||
    country === "PHL" ||
    country === "THA" ||
    country === "VNM"
  ) {
    return country;
  }
  return "";
};

export default Root;
