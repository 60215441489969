import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const PageContentsWrapper: VFC<Props> = (props: Props) => {
  return <div>{props.children}</div>;
};

export default PageContentsWrapper;
