export const imageParser = (block) => {
  return block.data.caption
    ? `<figure>
            <img src="${block.data.file.url}" alt="${block.data?.caption}">            
            <figcaption>${block.data.caption}</figcaption>
          </figure>`
    : `<figure>
        <img src="${block.data.file.url}">
      </figure>`;
};
