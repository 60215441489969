/**
 * ChangePasswordModal.tsx
 */
/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, VFC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/hooks/useAuth";
import { usePut } from "src/hooks/usePut";
import { AvailLanguage } from "src/utils/getBrowserLanguage";
import Button from "../common/Button";
import InputField from "../common/InputField";
import StyledTab from "../tab/StyledTab";
import StyledTabs from "../tab/StyledTabs";
import TabPanel from "../tab/TabPanel";

interface Props {
  lang: string;
  open: boolean;
  setLang: Dispatch<SetStateAction<AvailLanguage>>;
  handleClose: () => void;
}
type GraphTypeSelection = "language" | "password";

const ChangePasswordModal: VFC<Props> = (props: Props) => {
  const [t, i18n] = useTranslation();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [graphType, setGraphType] = useState<GraphTypeSelection>("language");

  /**
   * tab change
   */
  const handleGraphTypeChange = (
    _: React.SyntheticEvent,
    newValue: GraphTypeSelection
  ) => setGraphType(newValue);

  /**
   * current password
   */
  const [currentPassword, setCurrentPassword] = useState("");
  /**
   * handle change current password
   */
  const onChangeCurrentPassword = (value: string) => {
    setCurrentPassword(value);
  };

  /**
   * new password
   */
  const [newPassword, setNewPassword] = useState("");
  /**
   * handle change new password
   */
  const onChangeNewPassword = (value: string) => {
    setNewPassword(value);
  };

  /**
   * confirmation password
   */
  const [confirmationPassword, setConfirmationPassword] = useState("");
  /**
   * handle change confirmation password
   */
  const onChangeConfirmationPassword = (value: string) => {
    setConfirmationPassword(value);
  };

  /**
   * error message
   */
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  useEffect(() => {
    if (newPassword !== "" && newPassword.length < 8) {
      setErrorMessage(t("modal.change_password.error.length"));
      return;
    }

    if (
      newPassword !== "" &&
      confirmationPassword !== "" &&
      newPassword !== confirmationPassword
    ) {
      setErrorMessage(t("modal.change_password.error.match"));
    } else {
      setErrorMessage("");
    }
  }, [newPassword, confirmationPassword]);

  /**
   * tab
   */
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const { put, isLoading } = usePut({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publisherMembers/password`,
    params: {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirm: confirmationPassword,
    },
    onSuccess: () => {
      enqueueSnackbar(t("modal.change_password.success"), {
        variant: "success",
      });
      props.handleClose();
      setCurrentPassword("");
      setNewPassword("");
      setConfirmationPassword("");
    },
    onError: () => {
      enqueueSnackbar(t("modal.change_password.error.fail"), {
        variant: "error",
      });
    },
  });

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
          setCurrentPassword("");
          setNewPassword("");
          setConfirmationPassword("");
        }}
        maxWidth="xs"
        fullWidth
      >
        <div className="p-8">
          <div className="flex justify-between items-center">
            <span className="text text-lg font-bold">
              {t("modal.change_password.title")}
            </span>
            <IconButton
              onClick={() => {
                props.handleClose();
                setCurrentPassword("");
                setNewPassword("");
                setConfirmationPassword("");
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex items-center">
            <StyledTabs
              value={graphType}
              onChange={handleGraphTypeChange}
              aria-label="Graph Type Selection"
            >
              <StyledTab
                value="language"
                label={t("modal.change_password.language")}
                {...a11yProps(0)}
              />
              <StyledTab
                value="password"
                label={t("modal.change_password.password")}
                {...a11yProps(1)}
              />
            </StyledTabs>
          </div>
          <TabPanel
            value={graphType}
            index="language"
            className="w-[100%] flex flex-col gap-2"
          >
            <div className="flex items-center p-4 pt-2">
              <Select
                className="w-full"
                value={props.lang}
                onChange={(e) => {
                  props.setLang(e.target.value as AvailLanguage);
                  localStorage.setItem(
                    "vritimes_language",
                    e.target.value as AvailLanguage
                  );
                }}
              >
                <MenuItem disabled={props.lang === "en"} value="en">
                  English
                </MenuItem>
                <MenuItem disabled={props.lang === "id"} value="id">
                  Indonesian
                </MenuItem>
                <MenuItem disabled={props.lang === "vn"} value="vn">
                  Vietnamese
                </MenuItem>
                <MenuItem disabled={props.lang === "th"} value="th">
                  Thai
                </MenuItem>
              </Select>
            </div>
          </TabPanel>
          <TabPanel
            value={graphType}
            index="password"
            className="w-[100%] flex flex-col gap-2"
          >
            <div className="py-4 grid gap-4">
              <InputField
                label={t("sidemenu.reset_password.current_password")}
                type="password"
                value={currentPassword}
                onChange={onChangeCurrentPassword}
                placeholder={t(
                  "sidemenu.reset_password.current_password.placeholder"
                )}
              />
              <InputField
                label={t("sidemenu.reset_password.new_password")}
                type="password"
                value={newPassword}
                onChange={onChangeNewPassword}
                placeholder={t(
                  "sidemenu.reset_password.new_password.placeholder"
                )}
              />
              <InputField
                label={t("sidemenu.reset_password.confirmation_password")}
                type="password"
                value={confirmationPassword}
                onChange={onChangeConfirmationPassword}
                placeholder={t(
                  "sidemenu.reset_password.confirmation_password.placeholder"
                )}
              />
            </div>
            <div className="text-base text-red-400">
              {errorMessage !== "" && errorMessage}
            </div>
            <div className="flex justify-end">
              <Button
                text="Submit"
                disabled={
                  currentPassword === "" ||
                  newPassword === "" ||
                  confirmationPassword === "" ||
                  newPassword !== confirmationPassword ||
                  newPassword.length < 8
                }
                onClick={() => {
                  put({
                    params: {
                      current_password: currentPassword,
                      new_password: newPassword,
                      new_password_confirm: confirmationPassword,
                    },
                  });
                }}
              />
            </div>
          </TabPanel>
        </div>
      </Dialog>
    </>
  );
};

export default ChangePasswordModal;
