import { BusinessCategory } from "src/entities/BusinessCategory";

const createBusinessCategories = (
  businessCategories?: BusinessCategory[],
  selectedCategories?: string[]
) => {
  const businessCategoriesReq = new Array<BusinessCategory>();

  // eslint-disable-next-line array-callback-return
  selectedCategories?.map((value) => {
    const businessCategory = businessCategories?.find(
      (category) => category.name === value
    );
    businessCategoriesReq.push(businessCategory);
  });

  return businessCategoriesReq;
};

export default createBusinessCategories;
