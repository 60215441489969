import CloseIcon from "@mui/icons-material/Close";
import { Backdrop, CircularProgress, Dialog, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router";
import { usePost } from "src/hooks/usePost";
import Button from "../components/common/Button";
import InputField from "../components/common/InputField";

interface Props {
  signIn?: (
    email: string,
    password: string,
    navigate: NavigateFunction,
    onSuccess: () => void,
    onError: () => void,
    path: string
  ) => Promise<boolean>;
  signOut?: (navigate: NavigateFunction) => boolean;
  lang: string;
  setPath: Dispatch<SetStateAction<string>>;
  path: string;
}

/**
 * Login page component.
 */
const SignInPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailForReset, setEmailForReset] = useState("");
  const [t, i18n] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * dialog open
   */
  const [open, setOpen] = useState(false);
  /**
   * handle open dialog
   */
  const handleClickOpen = () => {
    setOpen(true);
  };
  /**
   * handle close dialog
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Backdrop open
   */
  const [backdropOpen, setBackdropOpen] = useState(false);
  /**
   * handle open backdrop
   */
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };
  /**
   * handle close backdrop
   */
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  /**
   * isSent
   */
  const [isSent, setIsSent] = useState(false);
  /**
   * handle change isSent
   */
  const onChangeIsSent = () => {
    setIsSent(true);
  };
  /**
   * handle change isSent
   */
  const onChangeIsNotSent = () => {
    setIsSent(false);
  };

  /**
   * sent code
   */
  const [sentCode, setSentCode] = useState("");
  /**
   * handle change sent code
   */
  const onChangeSentCode = (value: string) => {
    setSentCode(value);
  };
  /**
   * new password
   */
  const [newPassword, setNewPassword] = useState("");
  /**
   * handle change new password
   */
  const onChangeNewPassword = (value: string) => {
    setNewPassword(value);
  };
  /**
   * confirmation password
   */
  const [confirmationPassword, setConfirmationPassword] = useState("");
  /**
   * handle change confirmation password
   */
  const onChangeConfirmationPassword = (value: string) => {
    setConfirmationPassword(value);
  };

  /**
   * error message
   */
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (newPassword !== "" && newPassword.length < 8) {
      setErrorMessage(t("modal.change_password.error.length"));
      return;
    }

    if (
      newPassword !== "" &&
      confirmationPassword !== "" &&
      newPassword !== confirmationPassword
    ) {
      setErrorMessage(t("modal.change_password.error.match"));
    } else {
      setErrorMessage("");
    }
  }, [newPassword, confirmationPassword]);

  useEffect(() => {
    props.signOut(navigate);
  }, []);

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onChangeEmailForReset = (value: string) => {
    setEmailForReset(value);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  const onFailedSignin = () => {
    enqueueSnackbar(t("sign_in.fail"), { variant: "error" });
    handleBackdropClose();
  };

  const onSucceededSignin = () => {
    enqueueSnackbar(t("sign_in.success"), { variant: "success" });
    handleBackdropClose();
  };

  const onClick = () => {
    props.setPath(props.path);
    handleBackdropOpen();
    if (props.signIn) {
      props.signIn(
        email.toLocaleLowerCase(),
        password,
        navigate,
        onSucceededSignin,
        onFailedSignin,
        props.path
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== "Enter") return;
    onClick();
  };

  const { post: postReset } = usePost({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publisherMembers/password/forget`,
    params: {
      email: emailForReset,
    },
    onSuccess: () => {
      enqueueSnackbar(t("sign_in.send code"), {
        variant: "success",
      });
      onChangeIsSent();
    },
    onError: () => {
      enqueueSnackbar(t("error.login_again"), {
        variant: "error",
      });
    },
  });

  const { post: postNewPassword } = usePost({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publisherMembers/password/reset`,
    params: {
      email: emailForReset,
      confirmation_code: sentCode,
      password: newPassword,
      password_confirm: confirmationPassword,
    },
    onSuccess: () => {
      enqueueSnackbar(t("sign_in.reset_password"), {
        variant: "success",
      });
      onChangeIsNotSent();
      handleClose();
    },
    onError: () => {
      enqueueSnackbar(t("error.login_again"), {
        variant: "error",
      });
    },
  });

  return (
    <main className="flex-grow flex justify-center items-center">
      <Backdrop
        open={backdropOpen}
        onClick={handleBackdropClose}
        style={{ zIndex: 10001 }}
      >
        <CircularProgress />
      </Backdrop>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <div className="p-8">
          <div className="flex justify-between items-center">
            <span className="text text-lg font-bold">
              {t("sign_in.password_reset.title")}
            </span>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {isSent ? (
            <div className="py-4 grid gap-4">
              <InputField
                label={t("sign_in.password_reset.email")}
                disabled
                type="text"
                value={emailForReset}
                onChange={onChangeEmailForReset}
                placeholder={t("sign_in.password_reset.email.placeholder")}
              />
              <InputField
                label={t("sign_in.password_reset.sent_code")}
                type="text"
                value={sentCode}
                onChange={onChangeSentCode}
                placeholder={t("sidemenu.reset_password.sent_code.placeholder")}
              />
              <InputField
                label={t("sidemenu.reset_password.new_password")}
                type="password"
                value={newPassword}
                onChange={onChangeNewPassword}
                placeholder={t(
                  "sidemenu.reset_password.new_password.placeholder"
                )}
              />
              <InputField
                label={t("sidemenu.reset_password.confirmation_password")}
                type="password"
                value={confirmationPassword}
                onChange={onChangeConfirmationPassword}
                placeholder={t(
                  "sidemenu.reset_password.confirmation_password.placeholder"
                )}
              />
            </div>
          ) : (
            <div className="py-4">
              <InputField
                label={t("sign_in.email.label")}
                type="text"
                value={emailForReset}
                onChange={onChangeEmailForReset}
                placeholder={t("sign_in.email.placeholder")}
              />
            </div>
          )}
          <div className="text-base text-red-400">
            {errorMessage !== "" && isSent && errorMessage}
          </div>
          {isSent ? (
            <div className="flex justify-between">
              <button
                type="button"
                className="text-primary hover:underline"
                onClick={() => {
                  onChangeIsNotSent();
                }}
              >
                {t("sign_in.password_reset.reset_code.send_again")}
              </button>
              <Button
                text={t("general.submit")}
                disabled={emailForReset === ""}
                onClick={() => {
                  postNewPassword({});
                }}
              />
            </div>
          ) : (
            <div className="flex justify-end">
              <Button
                text={t("general.send")}
                disabled={emailForReset === ""}
                onClick={() => {
                  postReset({});
                }}
              />
            </div>
          )}
        </div>
      </Dialog>
      <div className="shadow-md max-w-sp w-96 p-8 bg-white rounded-2xl">
        <div className="flex justify-center w-full text-2xl font-bold">
          {t("sign_in.title")}
        </div>
        <div className="grid grid-cols-1 gap-3">
          <div>
            <InputField
              label={t("sign_in.email.label")}
              type="text"
              value={email}
              onChange={onChangeEmail}
              placeholder={t("sign_in.email.placeholder")}
            />
          </div>
          <div>
            <InputField
              label={t("sign_in.password.label")}
              type="password"
              value={password}
              onChange={onChangePassword}
              placeholder={t("sign_in.password.placeholder")}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="text-primary hover:underline"
              onClick={handleClickOpen}
            >
              {t("sign_in.forgot_password")}
            </button>
          </div>
          {/* TODO: Add the features to save information and to reveal password. */}
          <div>
            <Button onClick={onClick} text={t("sign_in.button")} isFullWidth />
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignInPage;
