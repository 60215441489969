/** @jsxImportSource @emotion/react */
import { OutputData } from "@editorjs/editorjs";
import { Backdrop, CircularProgress, css } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  VFC,
} from "react";
import { useDropzone } from "react-dropzone";
import { createReactEditorJS } from "react-editor-js";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BusinessCategory } from "src/entities/BusinessCategory";
import { CountryPath } from "src/entities/CountryPath";
import { GetArticlePublicationsResponse } from "src/entities/GetArticlePublicationsResponse";
import { GetArticlesResponse } from "src/entities/GetArticlesResponse";
import { PublisherInfoResponse } from "src/entities/PublisherInfoResponse";
import { SearchKeyword } from "src/entities/SearchKeyword";
import { UserInfoResponse } from "src/entities/UserInfoResponse";
import { Auth } from "src/hooks/useAuth";
import { Refetch, useFetch } from "src/hooks/useFetch";
import { useUpdateArticle } from "src/hooks/useUpdateArticle";
import { checkSubscripted } from "src/utils/checkSubscripted";
import ComfirmArticleStep from "../components/editArticle/ComfirmArticleStep";
import CreateArticleStep from "../components/editArticle/CreateArticleStep";
import CreateCompleteStep from "../components/editArticle/CreateCompleteStep";
import DistributionSettingStep from "../components/editArticle/DistributionSettingStep";
import PaymentArticleStep from "../components/editArticle/PaymentArticleStep";
import CreateArticleModal from "../components/modal/CreateArticleModal";
import SaveArticleModal from "../components/modal/SaveArticleModal";
import StepPageWrapper from "../components/page/StepPageWrapper";

interface Props {
  auth: Auth;
  country: CountryPath;
  publisherInfo?: PublisherInfoResponse;
  userInfo?: UserInfoResponse;
  credits?: number;
  refetchPublisherInfo: Refetch<PublisherInfoResponse>;
}

type Step = "CREATE" | "CONFIRM" | "PAYMENT" | "COMPLETE" | "DISTRIBUTION";

const STEPPER_HEIGHT = 80;

const EditArticlePage: VFC<Props> = (props: Props) => {
  /**
   * Common
   */
  const navigate = useNavigate();
  const [t] = useTranslation();

  /**
   * Get path parameters
   */
  const params = useParams();

  /**
   * Step state
   */
  const [currentStep, setCurrentStep] = useState<Step>(
    params.step === "DISTRIBUTION" ? "DISTRIBUTION" : "CREATE"
  );
  /**
   * Go to Distribution Setting step
   */
  const goToDistributionSettingStep = () => {
    setCurrentStep("DISTRIBUTION");
  };
  /**
   * Go to confirm step
   */
  const goToConfirmStep = () => {
    setCurrentStep("CONFIRM");
  };
  /**
   * Go back to dashboard
   */
  const goBackToDashboard = () => {
    navigate("/dashboard");
  };
  /**
   * Go to confirm step
   */
  const goBackToCreateStep = () => {
    setCurrentStep("CREATE");
  };
  /**
   * Go back to confirm step
   */
  const goBackToConfirmStep = () => {
    setCurrentStep("CONFIRM");
  };
  /**
   * Go to payment step
   */
  const goToPaymentStep = () => {
    setCurrentStep("PAYMENT");
  };
  /**
  /**
   * Go to compete step
   */
  const goToCompleteStep = () => {
    setCurrentStep("COMPLETE");
  };

  /**
   * Get selected(editing) article
   */
  const { data: article } = useFetch<GetArticlesResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articles/${params.publisherId}/${params.slug}`,
    headers: {
      country: props.country,
    },
  });
  /**
   * Set the selected article information
   */
  useEffect(() => {
    if (article && article.data) {
      setTitle(article.data.title);
      setPublishedAt(DateTime.fromISO(article.data.published_at));
      setOutputBody(article.data.body);
      setSummary(article.data.summary);
      setMediaContact(article.data.media_contact);
      setCompanyInfo(article.data.company_info);
      setSelectedCategories(
        article.data.meta_data.business_categories?.map((category) => {
          return category.name;
        })
      );
      setThumbnailAsDefault(article.data.use_thumbnail_as_featured_image);
    }
  }, [article]);

  /**
   * PR Title settings
   */
  /**
   * PR Title parameters
   */
  const [title, setTitle] = useState("");
  /**
   * Handle the title
   * @param value PR title
   */
  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  /**
   * PR Summary settings
   */
  /**
   * PR Summary parameters
   */
  const [summary, setSummary] = useState("");
  /**
   * Handle the summary
   * @param value PR summary
   */
  const handleSummaryChange = (value: string) => {
    setSummary(value);
  };

  /**
   * Published at settings
   */
  /**
   * Published at parameters
   */
  const [publishedAt, setPublishedAt] = useState(DateTime.now());
  /**
   * Handle published at
   * @param value post date
   */
  const handlePublishedAtChange = (value: DateTime) => {
    setPublishedAt(value);
  };

  /**
   * Image file hooks
   */
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } =
    useDropzone({
      multiple: false,
    });

  /**
   * Thumbnail flag settings
   */
  /**
   * Thumbnail flag parameters
   */
  const [thumbnailAsDefault, setThumbnailAsDefault] = useState(false);
  /**
   * Handle thumbnail flag
   */
  const handleThumbnailAsDefault = (e: ChangeEvent<HTMLInputElement>) => {
    setThumbnailAsDefault(e.target.checked);
  };

  /**
   * Business categories settings
   */
  /**
   * Business categories parameters
   */
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  /**
   * Get business categories
   */
  const { data: businessCategories } = useFetch<BusinessCategory[]>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/businessCategories`,
  });
  /**
   * Handle business categories
   */
  const handleBusinessCategories = (
    _: SyntheticEvent<Element, Event>,
    values: string[]
  ) => {
    if (values.length > 5) {
      return;
    }
    setSelectedCategories(values);
  };

  /**
   * Search Keywords settings
   */
  /**
   * Search Keywords parameters
   */
  const [searchKeywords, setSearchKeywords] = useState<SearchKeyword[]>([]);
  /**
   * Get the Search Keywords
   */
  useFetch<GetArticlePublicationsResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articlePublications/${props?.userInfo?.publisher_id}`,
    headers: {
      country: props.country,
    },
    onSuccess: (articlePublicationsResponse) => {
      if (articlePublicationsResponse.data.length > 0) {
        articlePublicationsResponse.data.forEach((data) => {
          if (data.slug === params.slug) {
            setSearchKeywords(data.search_words);
          }
        });
      }
    },
  });

  /**
   * Company Info settings
   */
  /**
   * Company Info parameters
   */
  const [companyInfo, setCompanyInfo] = useState("");
  /**
   * Handle company info
   * @param value company info
   */
  const handleCompanyInfo = (value: string) => {
    setCompanyInfo(value);
  };

  /**
   * Media contact settings
   */
  /**
   * Media contact parameters
   */
  const [mediaContact, setMediaContact] = useState("");
  /**
   * Handle media contact
   * @param value media contact
   */
  const handleMediaContact = (value: string) => {
    setMediaContact(value);
  };

  /**
   * Dropdown settings
   */
  /**
   * Dropdown parameters
   */
  const [backdropOpen, setBackdropOpen] = useState(false);
  /**
   * Open backdrop
   */
  const handleBackDropOpen = () => {
    setBackdropOpen(true);
  };
  /**
   * Close backdrop
   */
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  /**
   * ReactEditor settings
   */
  /**
   * ReactEditor parameters
   */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const ReactEditorJS = createReactEditorJS();
  const [outputBody, setOutputBody] = useState<OutputData>();
  const editorCore = useRef(null);
  /**
   * This is the callback function for initializing ReactEditor
   */
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);
  /**
   * This is the callback function for save the output body
   */
  const handleSave = useCallback(async () => {
    await editorCore.current?.save().then((outputData: OutputData) => {
      setOutputBody(outputData);
    });
  }, [setOutputBody]);

  /**
   * Modal settings
   */
  /**
   * Modal parameters
   */
  const [open, setOpen] = useState(false);
  /**
   * Open the confirmation modal
   */
  const handleClickOpen = () => {
    setOpen(true);
  };
  /**
   * Close the confirmation modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Save Modal settings
   */
  /**
   * Modal parameters
   */
  const [saveOpen, setSaveOpen] = useState(false);
  /**
   * Open the confirmation modal
   */
  const handleClickSaveOpen = () => {
    setSaveOpen(true);
  };
  /**
   * Close the confirmation modal
   */
  const handleSaveClose = () => {
    setSaveOpen(false);
  };

  /**
   * API Response
   */
  const [apiResponse, setApiResponse] = useState<GetArticlesResponse>();

  const onSuccess = (response: GetArticlesResponse) => {
    setApiResponse(response);
    goToCompleteStep();
    props.refetchPublisherInfo();
  };

  /**
   * Update an article
   */
  const { updateArticle } = useUpdateArticle({
    input: {
      editorCore: editorCore,
      article: article,
      publisherId: params?.publisher_id,
      title: title,
      outputBody: outputBody,
      country: props.country,
      acceptedFiles: acceptedFiles,
      publishedAt: publishedAt,
      businessCategories: businessCategories,
      slug: params?.slug,
      selectedCategories: selectedCategories,
      summary: summary,
      companyInfo: companyInfo,
      mediaContact: mediaContact,
      useThumbnailAsFeaturedImage: thumbnailAsDefault,
      searchKeywords: searchKeywords,
    },
    functions: {
      handleBackDropOpen: handleBackDropOpen,
      handleClose: handleClose,
      handleBackdropClose: handleBackdropClose,
      handleSave: handleSave,
      handleComplete: onSuccess,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* create article modal */}
      <CreateArticleModal
        open={open}
        handleClose={handleClose}
        createArticle={updateArticle}
        country={props.country}
        handleBackDropOpen={handleBackDropOpen}
        is_subscripted={props.publisherInfo?.is_subscripted}
      />

      {/* save article modal */}
      <SaveArticleModal
        open={saveOpen}
        handleClose={handleSaveClose}
        createArticle={updateArticle}
        handleBackDropOpen={handleBackDropOpen}
      />

      {/* Content */}
      <StepPageWrapper>
        {currentStep === "CREATE" ||
        currentStep === "CONFIRM" ||
        currentStep === "PAYMENT" ? (
          <div css={stepperContainer}>
            {currentStep === "CONFIRM" ? (
              <div css={stepperReturnButtonWrapper}>
                <button
                  css={stepperReturnButton}
                  type="button"
                  onClick={goBackToCreateStep}
                >
                  <img
                    width={20}
                    css={stepContent}
                    alt="step1_gray"
                    src="/articleEditor/arrow.png"
                  />
                  <img
                    width={20}
                    css={stepContent}
                    alt="step1_gray"
                    src="/step/step_1_gray.png"
                  />
                  <div>{t("dashboard.table.actions.edit")}</div>
                </button>
              </div>
            ) : null}
            {currentStep === "CREATE" ? (
              <div css={stepperReturnButtonWrapper}>
                <button
                  css={stepperReturnButton}
                  type="button"
                  onClick={goBackToDashboard}
                >
                  <img
                    width={20}
                    css={stepContent}
                    alt="step1_gray"
                    src="/articleEditor/arrow.png"
                  />
                  <div>{t("create_press_release.complete.return.text")}</div>
                </button>
              </div>
            ) : null}
            {currentStep === "PAYMENT" ? (
              <div css={stepperReturnButtonWrapper}>
                <button
                  css={stepperReturnButton}
                  type="button"
                  onClick={goBackToConfirmStep}
                >
                  <img
                    width={20}
                    css={stepContent}
                    alt="step2_gray"
                    src="/articleEditor/arrow.png"
                  />
                  <img
                    width={20}
                    css={stepContent}
                    alt="step2_gray"
                    src="/step/step_2_gray.png"
                  />
                  <div>{t("dashboard.modal.button.confirm")}</div>
                </button>
              </div>
            ) : null}
            {currentStep === "PAYMENT" ? (
              <div css={stepperReturnButtonWrapper}>
                <button
                  css={stepperReturnButton}
                  type="button"
                  onClick={goBackToConfirmStep}
                >
                  <img
                    width={20}
                    css={stepContent}
                    alt="step2_gray"
                    src="/articleEditor/arrow.png"
                  />
                  <img
                    width={20}
                    css={stepContent}
                    alt="step2_gray"
                    src="/step/step_2_gray.png"
                  />
                  <div>{t("dashboard.modal.button.confirm")}</div>
                </button>
              </div>
            ) : null}
            <div css={stepperWrapper}>
              <div css={currentStep === "CREATE" ? step : disabledStep}>
                {currentStep === "CREATE" ? (
                  <img
                    width={30}
                    alt="step1_blue"
                    src="/step/step_1_blue.png"
                  />
                ) : (
                  <img
                    width={30}
                    alt="step1_gray"
                    src="/step/step_1_gray.png"
                  />
                )}
                {t("dashboard.modal.button.edit")}
              </div>
              <div css={currentStep === "CONFIRM" ? step : disabledStep}>
                {currentStep === "CONFIRM" ? (
                  <img
                    width={30}
                    alt="step2_blue"
                    src="/step/step_2_blue.png"
                  />
                ) : (
                  <img
                    width={30}
                    alt="step2_gray"
                    src="/step/step_2_gray.png"
                  />
                )}
                {t("dashboard.modal.button.confirm")}
              </div>
              {article?.data.state === "draft" ||
              article?.data.state === "payment_failed" ? (
                <div css={currentStep === "PAYMENT" ? step : disabledStep}>
                  {currentStep === "PAYMENT" ? (
                    <img
                      width={30}
                      alt="step3_blue"
                      src="/step/step_3_blue.png"
                    />
                  ) : (
                    <img
                      width={30}
                      alt="step3_gray"
                      src="/step/step_3_gray.png"
                    />
                  )}
                  {t("dashboard.modal.button.payment")}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {currentStep === "CREATE" ? (
          <CreateArticleStep
            // common
            auth={props.auth}
            country={props.country}
            publisherInfo={props.publisherInfo}
            userInfo={props.userInfo}
            ReactEditorJS={ReactEditorJS}
            // article information
            publishedAt={publishedAt}
            title={title}
            summary={summary}
            selectedCategories={selectedCategories}
            thumbnailAsDefault={thumbnailAsDefault}
            companyInfo={companyInfo}
            mediaContact={mediaContact}
            outputBody={outputBody}
            businessCategories={businessCategories}
            isPublished={
              article?.data.state === "published" ||
              article?.data.state === "scheduled"
            }
            thumbnailUrl={article?.data.thumbnail_image_url}
            // dropzone param
            acceptedFiles={acceptedFiles}
            isDragAccept={isDragAccept}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            // functions
            handlePublishedAtChange={handlePublishedAtChange}
            handleBusinessCategories={handleBusinessCategories}
            handleTitleChange={handleTitleChange}
            handleSummaryChange={handleSummaryChange}
            handleThumbnailAsDefault={handleThumbnailAsDefault}
            handleInitialize={handleInitialize}
            handleSave={handleSave}
            handleCompanyInfo={handleCompanyInfo}
            handleMediaContact={handleMediaContact}
            handleClickSaveOpen={handleClickSaveOpen}
            goToConfirmStep={goToConfirmStep}
          />
        ) : null}
        {currentStep === "CONFIRM" ? (
          <ComfirmArticleStep
            // common
            publisherInfo={props.publisherInfo}
            country={props.country}
            // article information
            publishedAt={publishedAt}
            title={title}
            summary={summary}
            selectedCategories={selectedCategories}
            thumbnailAsDefault={thumbnailAsDefault}
            companyInfo={companyInfo}
            mediaContact={mediaContact}
            outputBody={outputBody}
            businessCategories={businessCategories}
            isPublished={
              article?.data.state === "published" ||
              article?.data.state === "scheduled"
            }
            thumbnailUrl={article?.data.thumbnail_image_url}
            // Dropzone param
            acceptedFiles={acceptedFiles}
            // functions
            handleClickSaveOpen={handleClickSaveOpen}
            updateArticle={() => {
              updateArticle("PUBLISHED");
            }}
            goToPaymentStep={goToPaymentStep}
          />
        ) : null}
        {currentStep === "PAYMENT" ? (
          <PaymentArticleStep
            // common
            // publisherInfo={props.publisherInfo}
            // country={props.country}
            // article information
            // publishedAt={publishedAt}
            credits={props.credits}
            title={title}
            is_subscripted={checkSubscripted(
              props.publisherInfo.subscripted_countries,
              props.country
            )}
            // summary={summary}
            // selectedCategories={selectedCategories}
            // thumbnailAsDefault={thumbnailAsDefault}
            // companyInfo={companyInfo}
            // mediaContact={mediaContact}
            // outputBody={outputBody}
            // businessCategories={businessCategories}
            thumbnailUrl={article?.data.thumbnail_image_url}
            // functions
            handleSave={handleSave}
            handleClickSaveOpen={handleClickSaveOpen}
            handleClickOpen={handleClickOpen}
          />
        ) : null}
        {currentStep === "COMPLETE" ? (
          <CreateCompleteStep
            apiResponse={apiResponse}
            country={props.country}
          />
        ) : null}
        {currentStep === "DISTRIBUTION" ? (
          <DistributionSettingStep
            title={title}
            thumbnailUrl={article?.data.thumbnail_image_url}
            searchKeywords={searchKeywords}
            setSearchKeywords={setSearchKeywords}
            userInfo={props.userInfo}
          />
        ) : null}
      </StepPageWrapper>
    </LocalizationProvider>
  );
};

const stepperContainer = css`
  display: flex;
  justify-content: center;
  position: relative;
  background: #eaeaea;
  height: ${STEPPER_HEIGHT}px;
`;

const stepperWrapper = css`
  display: flex;
  margin-top: 12px;
`;

const step = css`
  background: #ffffff;
  padding: 4px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  color: #0045ff;
`;

const disabledStep = css`
  padding: 4px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  color: #969696;
`;

const stepperReturnButtonWrapper = css`
  position: absolute;
  left: 24px;
  top: 26px;
`;

const stepperReturnButton = css`
  display: flex;
  align-items: center;
`;

const stepContent = css`
  margin-right: 8px;
`;

export default EditArticlePage;
