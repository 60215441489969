import { EChartsOption } from "echarts";
import { useMemo, VFC } from "react";
import { GraphData } from "src/entities/AnalyticsSummaryResponse";
import { Charts } from "../../common/Chart";

interface Props {
  title: string;
  left: string;
  data: GraphData;
}
const DoughtChart: VFC<Props> = (props: Props) => {
  const pieChartOption: EChartsOption = useMemo(
    () => ({
      title: {
        text: props.title,
        left: props.left,
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        align: "right",
        right: "right",
        top: "middle",
        icon: "circle",
        itemHeight: 25,
        itemWidth: 25,
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          return `${params.seriesName}<br />${params.name}: ${params.percent}%`;
        },
      },
      color: [
        "#332288",
        "#88CCEE",
        "#44AA99",
        "#117733",
        "#999933",
        "#DDCC77",
        "#CC6677",
        "#882255",
        "#AA4499",
      ],
      series: [
        {
          name: props.title,
          type: "pie",
          top: 8,
          radius: ["40%", "80%"],
          data: props.data.data.map((data, index) => ({
            value: data,
            name: props.data.labels[index],
          })),
          symbol: "none",
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "inside",
            formatter: "{d}%",
          },
          labelLine: {
            show: false,
          },
          center: ["40%", "50%"],
        },
      ],
    }),
    [props.data]
  );
  return <Charts options={pieChartOption} />;
};
export default DoughtChart;
