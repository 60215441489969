import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const StyledTableBody: VFC<Props> = (props: Props) => {
  return <tbody className="text-text-main">{props.children}</tbody>;
};

export default StyledTableBody;
