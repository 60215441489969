import { CountryPath } from "src/entities/CountryPath";

const getCountryCode = (countryPath: CountryPath) => {
  switch (countryPath) {
    case "IDN":
      return "id";
    case "SGP":
      return "sg";
    case "PHL":
      return "ph";
    case "MYS":
      return "my";
    case "THA":
      return "th";
    case "VNM":
      return "vn";
    default:
      return "id";
  }
};

export default getCountryCode;
