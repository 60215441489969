import { Autocomplete, styled } from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-tag": {
    backgroundColor: "rgb(203, 228, 255)",
    color: "blue",
    fontSize: "15px",
  },
});

export default StyledAutocomplete;
