import axios from "axios";
import { useAuth } from "./useAuth";

interface DownloadRequest {
  type: "feedback" | "publication";
  articleId: string;
}

export const useDownload = () => {
  const { authInfo } = useAuth();

  const download = async ({ type, articleId }: DownloadRequest) => {
    const url = `${process.env.REACT_APP_DOMAIN_V2}/api/v2/articles/${articleId}/${type}_report/download`;
    try {
      await axios
        .get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${authInfo.accessToken}`,
          },
        })
        .then((res) => {
          // 別タブで開く
          window.open(res?.data?.url, "_blank");
        });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    download,
  };
};
