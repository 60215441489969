import { CountryPath } from "src/entities/CountryPath";
import { PublisherCredit } from "src/entities/PublisherCredit";

export const getCredit = (
  publisherCredits: PublisherCredit[] | undefined,
  country: CountryPath
): number => {
  const publisherCredit = publisherCredits?.find(
    (value) => value.country === country
  );
  return publisherCredit ? publisherCredit.credits : 0;
};
