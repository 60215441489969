import { CountryPath } from "src/entities/CountryPath";

const getCountryLanguage = (countryPath: CountryPath) => {
  switch (countryPath) {
    case "IDN":
      return "bahasa Indonesia";
    case "SGP":
      return "English";
    case "PHL":
      return "English";
    case "MYS":
      return "English";
    case "THA":
      return "Thai";
    case "VNM":
      return "Vietnamese";
    default:
      return "Unknown";
  }
};

export default getCountryLanguage;
