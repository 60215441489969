export const LANGUAGES = [
  {
    code: "ENG",
    name: "English",
  },
  {
    code: "IND",
    name: "Bahasa Indonesia",
  },
  {
    code: "THA",
    name: "ภาษาไทย",
  },
  {
    code: "VIE",
    name: "Tiếng Việt",
  },
];
