function isPngOrJpeg(file: File): boolean {
  const fileName = file.name;
  const extension = fileName.split(".").pop()?.toLowerCase();

  if (
    extension === "png" ||
    extension === "jpeg" ||
    extension === "jpg" ||
    extension === "webp"
  ) {
    return true;
  }
  return false;
}

export default isPngOrJpeg;
