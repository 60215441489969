import axios from "axios";
import { LoginSuccessResponse } from "src/hooks/useAuth";
import { PostImageByFileResponseSchemaV2 } from "../types/image";

const uploadImageByFile = async (
  reqParamas: File,
  onError?: () => void
  // eslint-disable-next-line consistent-return
): Promise<PostImageByFileResponseSchemaV2> => {
  try {
    // Auth
    const localAuthInfo: LoginSuccessResponse = JSON.parse(
      window.localStorage.getItem("authInfo")
    );
    if (!localAuthInfo) {
      throw new Error("Not logged in");
    }

    const formData = new FormData();
    formData.append("image", reqParamas);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.post(
      `${process.env.REACT_APP_DOMAIN_V2}/api/v2/image/upload`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localAuthInfo.accessToken}`,
        },
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  } catch (e) {
    console.error(e);
    onError?.();
  }
};

export default uploadImageByFile;
