/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton } from "@mui/material";
import {
  Document,
  Font,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { GetArticlesSuccessResponse } from "src/entities/GetArticlesSuccessResponse";
import { PublisherInfoResponse } from "src/entities/PublisherInfoResponse";
import Button from "../common/Button";

interface Props {
  open: boolean;
  handleClose: () => void;
  publisherInfo: PublisherInfoResponse;
  article?: GetArticlesSuccessResponse;
}

const DownloadPreview: VFC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth="md"
    >
      <div style={{ marginTop: 12, marginRight: 12, textAlign: "right" }}>
        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <PDFViewer style={{ height: 640, maxHeight: "70vh" }} showToolbar={false}>
        <PdfDocument
          publisherInfo={props.publisherInfo}
          article={props.article}
        />
      </PDFViewer>
      <div
        style={{
          width: "100%",
          padding: 12,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ width: "fit-content" }}>
          <PDFDownloadLink
            document={
              <PdfDocument
                publisherInfo={props.publisherInfo}
                article={props.article}
              />
            }
            fileName={`${props.article?.title}.pdf`}
            style={{ textDecoration: "none", width: "fit-content" }}
          >
            <Button text="Download" variant="primary" />
          </PDFDownloadLink>
        </div>
      </div>
    </Dialog>
  );
};

type PdfDocumentProps = {
  publisherInfo: PublisherInfoResponse;
  article: GetArticlesSuccessResponse;
};
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});
const styles = StyleSheet.create({
  wrapper: {
    padding: 32,
    fontFamily: "Open Sans",
    width: "100%",
  },
  header: {
    fontSize: 14,
    borderBottom: "1px solid black",
    width: "100%",
    marginBottom: 24,
  },
  infoWrapper: {
    marginBottom: 16,
  },
  titleWrapper: {
    width: "100%",
    padding: "0px 12px",
    textAlign: "center",
    marginBottom: 16,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
  },
  publisher: {
    fontSize: 12,
    fontWeight: "bold",
  },
  date: {
    fontSize: 12,
  },
  paragraghWrapper: {
    marginBottom: 8,
  },
  h2: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 4,
  },
  h3: {
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 4,
    marginBottom: 4,
  },
  h4: {
    fontSize: 12,
    marginTop: 4,
    marginBottom: 4,
  },
  summary: {
    fontSize: 12,
    marginBottom: 16,
    whiteSpace: "pre-wrap",
  },
  paragraph: {
    fontSize: 12,
  },
  bold: {
    fontWeight: "bold",
  },
  newLine: {
    marginBottom: 12,
  },
  imageWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "12px 0px",
  },
  image: {
    maxHeight: 256,
    maxWidth: 320,
  },
  otherInfoTitle: {
    fontSize: 12,
    marginTop: 16,
    fontWeight: 700,
  },
  otherInfoContent: {
    fontSize: 12,
    marginBottom: 16,
    whiteSpace: "pre-wrap",
  },
});

const PdfDocument = ({ publisherInfo, article }: PdfDocumentProps) => {
  const [t] = useTranslation();
  return (
    <Document>
      <Page size="A4" style={styles.wrapper}>
        <View style={styles.header}>
          <Text>{t("article.press_release")}</Text>
        </View>
        <View style={styles.infoWrapper}>
          <Text style={styles.publisher}>{publisherInfo.publisher_name}</Text>
          <Text style={styles.date}>
            {DateTime.fromISO(article.published_at).toFormat("MMMM dd yyyy")}
          </Text>
        </View>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{article.title}</Text>
        </View>
        <View>
          <Text style={styles.summary}>{article.summary}</Text>
        </View>
        {article.use_thumbnail_as_featured_image ? (
          <View style={styles.imageWrapper}>
            <Image src={article.thumbnail_image_url} style={styles.image} />
          </View>
        ) : null}
        {article.body.blocks.map((block) => {
          if (block.type === "header") {
            if (block.data.level === 2) {
              return (
                <View key={block.data.text} style={styles.h2}>
                  <Text>{block.data.text}</Text>
                </View>
              );
            }
            if (block.data.level === 3) {
              return (
                <View key={block.data.text} style={styles.h3}>
                  <Text>{block.data.text}</Text>
                </View>
              );
            }
            return (
              <View key={block.data.text} style={styles.h4}>
                <Text>{block.data.text}</Text>
              </View>
            );
          }
          if (block.type === "paragraph") {
            const texts: string[] = block.data.text.split("<br>");
            return texts.map((text) => {
              if (text.length === 0) {
                return <View key={text} style={styles.newLine} />;
              }

              return (
                <View key={text}>
                  {/* <Text style={styles.paragraph}>{text}</Text> */}
                  <Text style={styles.paragraph}>
                    {text.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")}
                  </Text>
                </View>
              );
            });
          }
          if (block.type === "image") {
            return (
              <View style={styles.imageWrapper}>
                <Image src={block.data.file.url} style={styles.image} />
              </View>
            );
          }
          return null;
        })}
        <View>
          <Text
            style={styles.otherInfoTitle}
          >{`About ${publisherInfo.publisher_name}`}</Text>
          <Text style={styles.otherInfoContent}>{article.company_info}</Text>
        </View>
        <View>
          <Text style={styles.otherInfoTitle}>{t("article.contact")}</Text>
          <Text style={styles.otherInfoContent}>{article.media_contact}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default DownloadPreview;
