import { VFC } from "react";

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const StyledTableHeader: VFC<Props> = (props: Props) => {
  return (
    <thead className="text-md text-table-header h-12 border-b">
      {props.children}
    </thead>
  );
};

export default StyledTableHeader;
