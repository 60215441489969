export class NumberUtils {
  public static numberToFinancialDisplay = (input: number): string => {
    return input.toString().replace(/(?=\B(\d{3})+$)/g, ",");
  };

  public static percentageToDisplay = (input: number): string => {
    return `${(Math.round(input * 10000) / 100).toFixed(1)}%`;
  };

  public static numberToFinancialDisplayForIndonesia = (
    input: number
  ): string => {
    return input.toString().replace(/(?=\B(\d{3})+$)/g, ",");
  };

  public static getCurrency = (currencyCode: string) => {
    if (currencyCode === "idr") return "Rp";
    if (currencyCode === "sgd") return "S$";
    if (currencyCode === "myr") return "RM";
    if (currencyCode === "thb") return "Bht";
    if (currencyCode === "php") return "PHP";
    if (currencyCode === "vnd") return "VND";
    return "Rp";
  };
}
