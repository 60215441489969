/** @jsxImportSource @emotion/react */
import { css, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState, VFC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useReward } from "react-rewards";
import { useNavigate } from "react-router";
import { CountryPath } from "src/entities/CountryPath";
import { GetArticlesResponse } from "src/entities/GetArticlesResponse";
import getCountryCode from "src/utils/getContryCode";

interface Props {
  // common
  apiResponse?: GetArticlesResponse;
  country?: CountryPath;
  // article information
  // functions
}

const CreateCompleteStep: VFC<Props> = (props: Props) => {
  /**
   * Common parameters
   */
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { reward } = useReward("rewardId", "confetti", {
    spread: 90,
    lifetime: 100,
    onAnimationComplete: () => {
      setIsAnimating(true);
    },
  });

  /**
   * animation
   */
  const [isAnimating, setIsAnimating] = useState(false);
  const [isEndCongratulations, setIsEndCongratulations] = useState(false);
  const animationElement = document.getElementById("congratulations");
  animationElement?.addEventListener("animationend", () => {
    setIsEndCongratulations(true);
  });

  useEffect(() => {
    reward();
  }, []);

  return (
    <div>
      <div css={conentWrapper}>
        {!isEndCongratulations ? (
          <div
            css={
              isAnimating
                ? congratulationsAnimationWrapper
                : congratulationWrapper
            }
            id="congratulations"
          >
            <div css={congratulationsText}>
              {t("create_press_release.complete.title")}
            </div>
            <div css={congratulationsDescription}>
              {t("create_press_release.complete.description")}
            </div>
            <img
              css={congratulationsImg}
              src="/articleEditor/congratulations.png"
              alt="congratulations"
            />
            <span id="rewardId" />
          </div>
        ) : null}
        {isEndCongratulations ? (
          <div css={nextActionWrapper}>
            <div css={congratulationsText}>
              {t("create_press_release.complete.share.title")}
            </div>
            <div css={congratulationsDescription}>
              To maximize your exposure,
            </div>
            <div css={congratulationsDescription}>
              {t("create_press_release.complete.share.description")}
            </div>
            <div css={snsWrapper}>
              <a
                href={`http://www.facebook.com/share.php?u=https://www.vritimes.com/${getCountryCode(
                  props.country
                )}/${props.apiResponse.data.publisher.id}/${
                  props.apiResponse.data.slug
                }`}
                target="_blank"
                rel="noreferrer"
                css={shareSnsImg}
              >
                <img src="/articleEditor/facebook.png" alt="facebook" />
              </a>
              <a
                href={`https://twitter.com/share?url=https://www.vritimes.com/${getCountryCode(
                  props.country
                )}/${props.apiResponse.data.publisher.id}/${
                  props.apiResponse.data.slug
                }`}
                target="_blank"
                rel="noreferrer"
                css={shareSnsImg}
              >
                <img src="/articleEditor/twitter.png" alt="twitter" />
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite?url=https://www.vritimes.com/${getCountryCode(
                  props.country
                )}/${props.apiResponse.data.publisher.id}/${
                  props.apiResponse.data.slug
                }`}
                target="_blank"
                rel="noreferrer"
                css={shareSnsImg}
              >
                <img src="/articleEditor/linkedin.png" alt="linkedin" />
              </a>
            </div>
            <img
              css={congratulationsImg}
              src="/articleEditor/share.png"
              alt="congratulations"
            />
            <div css={urlCopyWrapper}>
              <CopyToClipboard
                text={`https://www.vritimes.com/${getCountryCode(
                  props.country
                )}/articles/${props.apiResponse.data.publisher.id}/${
                  props.apiResponse.data.slug
                }`}
              >
                <Tooltip title="Copy to clipboard" placement="top" arrow>
                  <button css={urlCopyButton} type="button">
                    {t("general.copy")}
                  </button>
                </Tooltip>
              </CopyToClipboard>
              <div css={urlContent}>
                {`URL: https://www.vritimes.com/${getCountryCode(
                  props.country
                )}/articles/${props.apiResponse.data.publisher.id}/${
                  props.apiResponse.data.slug
                }`}
              </div>
            </div>
            <div css={caution}>
              {props.apiResponse.data.state === "scheduled"
                ? `*Your press release will be distributed on ${DateTime.fromISO(
                    props.apiResponse.data.published_at
                  ).toFormat("MMM d")}`
                : t("create_press_release.complete.caution.description")}
            </div>
            <button
              onClick={() => {
                navigate("/dashboard");
              }}
              css={backButton}
              type="button"
              style={{ marginBottom: 16 }}
            >
              <img width={25} src="/articleEditor/arrow.png" alt="arrow" />
              <div css={goBackText}>
                {t("create_press_release.complete.return.text")}
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const conentWrapper = css`
  background: #ffffff;
  display: flex;
  height: calc(100vh - 80px);
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const congratulationsText = css`
  font-size: 5rem;
  font-weight: 600;
`;

const congratulationsDescription = css`
  font-size: 1.5rem;
  font-weight: 600;
`;

const congratulationsImg = css`
  height: 320px;
  margin-top: -24px;
`;

const congratulationWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const congratulationsAnimationWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slide-out-anim 1.5s ease-out forwards;

  @keyframes slide-out-anim {
    20% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
`;

const nextActionWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: fadeLeftAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
  @keyframes fadeLeftAnime {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const snsWrapper = css`
  display: flex;
  z-index: 1;
`;

const shareSnsImg = css`
  height: 40px;
  width: 40px;
  margin: 8px;
`;

const urlCopyWrapper = css`
  margin-top: 8px;
  display: flex;
  background: #e5e5e5;
  border-radius: 8px;
  font-weight: 500;
`;

const urlCopyButton = css`
  margin-right: 8px;
  border-radius: 8px;
  background: #ffffff;
  padding: 6px;
  border: 1px solid #969696;
  font-weight: 500;
  :active {
    background: #eaeaea;
  }
`;

const urlContent = css`
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

const backButton = css`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const caution = css`
  color: #6c6c6c;
`;

const goBackText = css`
  margin-left: 8px;
`;

export default CreateCompleteStep;
