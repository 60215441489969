import { VFC } from "react";

type AlignType = "left" | "center" | "right" | "justify" | "char";

interface Props {
  align?: AlignType;
  button?: boolean;
  rowSpan?: number;
  children?: string | JSX.Element | JSX.Element[];
}

const StyledTableCell: VFC<Props> = (props: Props) => {
  return (
    <td
      className={`px-4 py-4 border-r ${props.button ? "px-0 py-0" : ""}`}
      align={props.align ? props.align : "left"}
      rowSpan={props.rowSpan}
    >
      {props.children}
    </td>
  );
};

export default StyledTableCell;
