/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, css, DialogActions, IconButton } from "@mui/material";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Button from "../common/Button";

interface Props {
  // common
  // publisherInfo?: PublisherInfoResponse;
  // country: CountryPath;

  // article information
  // publishedAt: DateTime;
  title: string;
  credits?: number;
  // summary: string;
  // selectedCategories: string[];
  // thumbnailAsDefault: boolean;
  // companyInfo: string;
  // mediaContact: string;
  // outputBody: OutputData;
  // businessCategories: BusinessCategory[];

  is_subscripted: boolean;

  // Dropzone param
  acceptedFiles: File[];

  // functions
  handleSave: () => Promise<void>;
  handleClickSaveOpen: () => void;
  handleClickOpen: () => void;
}

const PaymentArticleStep: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  /**
   * dialog open
   */
  const [open, setOpen] = React.useState(false);
  /**
   * handle open dialog
   */
  const handleClickOpen = () => {
    setOpen(true);
  };
  /**
   * handle close dialog
   */
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div css={conentWrapper}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <div className="p-4">
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="text-base whitespace-pre-line text-black pt-4 pb-4">
            {t("payment.transition.description")}
            {/* <div css={dialogCaution}>
              <AiOutlineWarning size="28px" style={{ marginRight: 8 }} />
              {t("sidemenu.transition.caution")}
            </div> */}
          </div>
          <DialogActions>
            <Button
              text={t("payment.button.go_to_billing")}
              onClick={() => {
                props.handleSave();
                navigate("/credits_management");
              }}
            />
          </DialogActions>
        </div>
      </Dialog>
      <div className="w-100% p-8">
        <div className="w-100% border p-8 rounded-xl flex items-center">
          <div className="w-50% flex items-center">
            <div className="border rounded-lg">
              <img
                src={URL.createObjectURL(props.acceptedFiles[0])}
                alt="thumbnail"
                css={thumbnailStyle}
              />
            </div>
            <div className="ml-4">
              <div css={titleStyle} className="text text-lg font-bold">
                {props.title}
              </div>
            </div>
          </div>
          <div className="w-50% p-2 text">
            <div className="flex text-base justify-between border-b py-2 px-4 font-bold">
              <div>{t("payment.press_post.title")}</div>
              {props.is_subscripted ? (
                <div>{t("payment.press_post.credit_unlimit")}</div>
              ) : (
                <div>{t("payment.press_post.credit_normal")}</div>
              )}
            </div>
            <div className="flex justify-end text-base py-2 px-4 font-bold">
              {props.is_subscripted ? (
                <div>{t("payment.press_post.unlimited")}</div>
              ) : props.credits !== undefined && props.credits > 0 ? (
                <div>
                  {props.credits !== undefined && props.credits}{" "}
                  {t("payment.press_post.credit")} →{" "}
                  <span className="text-xl text-primary">
                    {props.credits !== undefined && props.credits - 1}
                  </span>{" "}
                  {t("payment.press_post.credit")}
                </div>
              ) : (
                <div className="text-yellow-600">
                  {t("payment.press_post.no_credit")}{" "}
                  <button
                    type="button"
                    onClick={() => {
                      handleClickOpen();
                    }}
                    className="underline hover:text-yellow-800 font-bold"
                  >
                    {t("payment.press_post.no_credit.purchase")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center mt-4">
          <Button
            text={t("payment.button.save_as_draft")}
            onClick={() => {
              props.handleSave();
              props.handleClickSaveOpen();
            }}
            className="mr-4"
          />
          <Button
            text={t("general.post")}
            disabled={
              !(
                (props.credits !== undefined && props.credits > 0) ||
                props.is_subscripted
              )
            }
            onClick={() => {
              props.handleSave();
              props.handleClickOpen();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const conentWrapper = css`
  background: #ffffff;
  display: flex;
  height: calc(100vh - 80px - 4rem);
`;

const thumbnailStyle = css`
  width: 160px;
  height: 120px;
  min-width: 160px;
  object-fit: contain;
`;

const titleStyle = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const dialogCaution = css`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-top: 12px;
  color: #808080;
`;

export default PaymentArticleStep;
