/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton } from "@mui/material";
import { t } from "i18next";
import { VFC } from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const SupportModal: VFC<Props> = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <div className="p-4">
        <div style={{ textAlign: "right" }}>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="px-12 pb-8">
          <div className="text-base whitespace-pre-line text-black pt-4 pb-4">
            {/* もしサポートが必要な場合は下記のメールアドレスに連絡してください */}
            {t("support_modal.description")}
          </div>
          <a
            className="text-base whitespace-pre-line text-blue pt-4 pb-12"
            href="mailto:marketing@vritimes.com"
          >
            marketing@vritimes.com
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default SupportModal;
