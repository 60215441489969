import { CountryPath } from "src/entities/CountryPath";

export const getLanguage = (country: CountryPath) => {
  switch (country) {
    case "IDN":
      return "IND";
    case "SGP":
      return "ENG";
    case "PHL":
      return "ENG";
    case "MYS":
      return "ENG";
    case "THA":
      return "THA";
    case "VNM":
      return "VIE";
    default:
      return "ENG";
  }
};
