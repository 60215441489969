/** @jsxImportSource @emotion/react */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Backdrop,
  css,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { NavigateFunction, useNavigate } from "react-router";
import { CountryPath } from "src/entities/CountryPath";
import { GetArticlesSuccessResponse } from "src/entities/GetArticlesSuccessResponse";
import { PublisherInfoResponse } from "src/entities/PublisherInfoResponse";
import { UserInfoResponse } from "src/entities/UserInfoResponse";
import { useDelete } from "src/hooks/useDelete";
import { useFetch } from "src/hooks/useFetch";
import getCountryName from "src/utils/getCountryName";
import ArticlePreview from "../components/article/ArticlePreview";
import DownloadPreview from "../components/article/DownloadPreview";
import Button from "../components/common/Button";
import PageContentsWrapper from "../components/page/PageContentsWrapper";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";
import StyledTable from "../components/table/StyledTable";
import StyledTableBody from "../components/table/StyledTableBody";
import StyledTableCell from "../components/table/StyledTableCell";
import StyledTableHeader from "../components/table/StyledTableHeader";
import StyledTableHeaderRow from "../components/table/StyledTableHeaderRow";
import StyledTableRow from "../components/table/StyledTableRow";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
  country: CountryPath;
  userInfo?: UserInfoResponse;
  isUserInfoLoading: boolean;
}

/**
 * Sort by
 */
type SortBy = "title" | "published_at" | "status" | "updated_at";
/**
 * Sort order
 */
type SortOrder = "asc" | "desc";

const DashboardPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [previewedSlug, setPreviewedSlug] = useState<string>("");
  const [selectedSlug, setSelectedSlug] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortBy>("published_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDownloadOpen = () => {
    setDownloadOpen(true);
  };

  const handleDownloadClose = () => {
    setDownloadOpen(false);
  };

  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleBackDropOpen = () => {
    setBackdropOpen(true);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const onClickPreview = (slug: string) => {
    setPreviewedSlug(slug);
    handleClickOpen();
  };

  const onClickDownload = (slug: string) => {
    setPreviewedSlug(slug);
    handleClickDownloadOpen();
  };

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  useEffect(() => {
    if (props.userInfo) {
      refetchPublisherInfo();
    }
  }, [props.country]);

  const {
    data: publisherInfo,
    isLoading: isPublisherInfo,
    refetch: refetchPublisherInfo,
  } = useFetch<PublisherInfoResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${props?.userInfo?.publisher_id}`,
    headers: {
      country: props.country,
    },
    skip: !props.userInfo || props.userInfo?.publisher_id === undefined,
    onError: () => {
      enqueueSnackbar(t("error.login_again"), {
        variant: "error",
      });
      props.signout(navigate);
    },
  });

  const sortedArticle =
    publisherInfo &&
    publisherInfo.articles &&
    sortArticles(publisherInfo?.articles, sortBy, sortOrder);

  const { exeDelete } = useDelete<UserInfoResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articles/${selectedSlug}`,
    headers: {
      country: props.country,
    },
    onSuccess: () => {
      handleDeleteClose();
      refetchPublisherInfo();
      enqueueSnackbar(t("dashboard.delete_succeeded"), {
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar(t("dashboard.delete_failed"), {
        variant: "error",
      });
    },
    onStart: handleBackDropOpen,
    onEnd: () => {
      handleDeleteClose();
      handleBackdropClose();
    },
  });

  return (
    <PageWrapper>
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onClickPreview(selectedSlug);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <AiOutlineEye size="24px" />
          </ListItemIcon>
          <ListItemText sx={{ minWidth: 80 }}>
            {t("dashboard.table.actions.preview")}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(
              `/article/${props?.userInfo?.publisher_id}/${selectedSlug}/CREATE`
            );
            handleMenuClose();
          }}
          disabled={
            publisherInfo?.articles?.find(
              (article) => article.slug === selectedSlug
            )?.state === "suspended"
          }
        >
          <ListItemIcon>
            <AiOutlineEdit size="24px" />
          </ListItemIcon>
          <ListItemText sx={{ minWidth: 80 }}>
            {t("dashboard.table.actions.edit")}
          </ListItemText>
        </MenuItem>
        {props.country !== "THA" && (
          <MenuItem
            onClick={() => {
              onClickDownload(selectedSlug);
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <AiOutlineDownload size="24px" />
            </ListItemIcon>
            <ListItemText sx={{ minWidth: 80 }}>
              {t("dashboard.table.actions.download")}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClickDeleteOpen();
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <AiOutlineDelete size="24px" />
          </ListItemIcon>
          <ListItemText sx={{ minWidth: 80 }}>
            {t("dashboard.table.actions.delete")}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <div className="p-4">
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={handleDeleteClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <div className="text-base text-black pt-4 pb-4">
              {t("dashboard.delete_press_release")}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              text={t("dashboard.modal.button.cancel")}
              onClick={handleDeleteClose}
            />
            <Button
              text={t("dashboard.modal.button.delete")}
              onClick={() => {
                exeDelete({});
              }}
            />
          </DialogActions>
        </div>
      </Dialog>
      {publisherInfo && previewedSlug ? (
        <ArticlePreview
          open={open}
          handleClose={handleClose}
          title={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.title
          }
          body={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.body
          }
          summary={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.summary
          }
          use_thumbnail_as_featured_image={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.use_thumbnail_as_featured_image
          }
          thumbnail_url={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.thumbnail_image_url
          }
          company_info={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.company_info
          }
          media_contact={
            publisherInfo.articles.find(
              (article) => article.slug === previewedSlug
            )?.media_contact
          }
          publisher_name={publisherInfo.publisher_name}
        />
      ) : null}
      {publisherInfo && previewedSlug ? (
        <DownloadPreview
          open={downloadOpen}
          handleClose={handleDownloadClose}
          publisherInfo={publisherInfo}
          article={publisherInfo.articles.find(
            (article) => article.slug === previewedSlug
          )}
        />
      ) : null}
      <div className="flex items-center justify-between">
        <PageHeader
          title={t("dashboard.page_header", {
            country: getCountryName(props.country),
          })}
        />
        <Button
          text={t("dashboard.create_press_release")}
          onClick={() => {
            navigate(`/article/new`);
          }}
        />
      </div>
      <PageContentsWrapper>
        {props.isUserInfoLoading || isPublisherInfo ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : publisherInfo && publisherInfo.articles?.length > 0 ? (
          <StyledTable>
            <StyledTableHeader>
              <StyledTableHeaderRow>
                <th className="px-4 w-5% border-r" align="center">
                  {" "}
                </th>
                <th
                  onClick={() => {
                    setSortBy("title");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-40% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("dashboard.table.title")}
                  {sortBy === "title" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => {
                    setSortBy("status");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-15% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("dashboard.table.state")}
                  {sortBy === "status" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => {
                    setSortBy("published_at");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-20% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("dashboard.table.post_date")}
                  {sortBy === "published_at" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => {
                    setSortBy("updated_at");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-20% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("dashboard.table.update_date")}
                  {sortBy === "updated_at" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
              </StyledTableHeaderRow>
            </StyledTableHeader>
            <StyledTableBody>
              {sortedArticle?.map((article) => {
                return (
                  <StyledTableRow key={article.slug}>
                    <StyledTableCell button>
                      <IconButton
                        onClick={(e) => {
                          setSelectedSlug(article.slug);
                          handleMenuClick(e);
                        }}
                        id="menu-button"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      <p className="truncate">{article.title}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className="truncate flex justify-center items-center">
                        {article.state === "published" && (
                          <>
                            <div className="w-2 h-2 mr-2 rounded-2xl bg-green-400">
                              {" "}
                            </div>
                            {t("dashboard.table.state.published")}
                          </>
                        )}
                        {article.state === "draft" && (
                          <>
                            <div className="w-2 h-2 mr-2 rounded-2xl bg-yellow-500">
                              {" "}
                            </div>
                            {t("dashboard.table.state.draft")}
                          </>
                        )}
                        {article.state === "scheduled" && (
                          <>
                            <div className="w-2 h-2 mr-2 rounded-2xl bg-sky-500">
                              {" "}
                            </div>
                            {t("dashboard.table.state.scheduled")}
                          </>
                        )}
                        {article.state === "suspended" && (
                          <Tooltip title={t("dashboard.table.suspended.error")}>
                            <div className="flex items-center">
                              <div className="w-2 h-2 mr-2 rounded-2xl bg-red-500">
                                {" "}
                              </div>
                              {t("dashboard.table.state.suspended")}
                            </div>
                          </Tooltip>
                        )}
                        {article.state === "payment_failed" && (
                          <Tooltip title={t("dashboard.table.failed.error")}>
                            <div className="flex items-center">
                              <div className="w-2 h-2 mr-2 rounded-2xl bg-red-500">
                                {" "}
                              </div>
                              {t("dashboard.table.state.failed")}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <p style={{ fontSize: 14 }}>
                        {DateTime.fromISO(article.published_at).toFormat(
                          "MMM dd yyyy h:mm a"
                        )}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell>
                      <p style={{ fontSize: 14 }}>
                        {article.updated_at
                          ? DateTime.fromISO(article.updated_at).toFormat(
                              "MMM dd yyyy h:mm a"
                            )
                          : null}
                      </p>
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      <Tooltip
                        title={t("dashboard.table.actions.preview")}
                        placement="top"
                      >
                        <button
                          type="button"
                          className="p-1 rounded-lg	hover:bg-slate-50"
                          onClick={() => {
                            onClickPreview(article.slug);
                          }}
                        >
                          <AiOutlineEye color="#92919F" size="24px" />
                        </button>
                      </Tooltip>
                      <Tooltip
                        title={t("dashboard.table.actions.edit")}
                        placement="top"
                      >
                        <button
                          onClick={() => {
                            navigate(
                              `/article/${userInfo?.publisher_id}/${article.slug}`
                            );
                          }}
                          type="button"
                          className="p-1 rounded-lg	hover:bg-slate-50"
                        >
                          <AiOutlineEdit color="#92919F" size="24px" />
                        </button>
                      </Tooltip>
                      <Tooltip
                        title={t("dashboard.table.actions.delete")}
                        placement="top"
                      >
                        <button
                          type="button"
                          className="p-1 rounded-lg	hover:bg-slate-50"
                          onClick={() => {
                            // onClickPreview(article.slug);
                            setSelectedSlug(article.slug);
                            handleClickDeleteOpen();
                          }}
                        >
                          <AiOutlineDelete color="#92919F" size="24px" />
                        </button>
                      </Tooltip>
                    </StyledTableCell> */}
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
            {/* <StyledTableFooter /> */}
          </StyledTable>
        ) : publisherInfo ? (
          <button
            type="button"
            onClick={() => {
              navigate("/article/new");
            }}
            css={addDescriptionStyle}
          >
            <div className="mb-2 font-semibold">
              {t("dashboard.add_press_release")}
            </div>
            <AiOutlinePlusCircle size="48px" />
          </button>
        ) : (
          <div>
            <div className="mb-2 font-semibold">{t("error.login_again")}</div>
          </div>
        )}
      </PageContentsWrapper>
    </PageWrapper>
  );
};

function sortArticles(
  articles: GetArticlesSuccessResponse[],
  sortBy: SortBy,
  order: SortOrder
): GetArticlesSuccessResponse[] {
  if (sortBy === "published_at") {
    if (order === "asc") {
      return articles.sort((a, b) => {
        return (
          DateTime.fromISO(a.published_at).toMillis() -
          DateTime.fromISO(b.published_at).toMillis()
        );
      });
    }
    if (order === "desc") {
      return articles.sort((a, b) => {
        return (
          DateTime.fromISO(b.published_at).toMillis() -
          DateTime.fromISO(a.published_at).toMillis()
        );
      });
    }
  } else if (sortBy === "updated_at") {
    if (order === "asc") {
      return articles.sort((a, b) => {
        return (
          DateTime.fromISO(a.updated_at).toMillis() -
          DateTime.fromISO(b.updated_at).toMillis()
        );
      });
    }
    if (order === "desc") {
      return articles.sort((a, b) => {
        return (
          DateTime.fromISO(b.updated_at).toMillis() -
          DateTime.fromISO(a.updated_at).toMillis()
        );
      });
    }
  } else if (sortBy === "title") {
    if (order === "asc") {
      return articles.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    }
    if (order === "desc") {
      return articles.sort((a, b) => {
        return b.title.localeCompare(a.title);
      });
    }
  } else if (sortBy === "status") {
    if (order === "asc") {
      return articles.sort((a, b) => {
        return a.state.localeCompare(b.state);
      });
    }
    if (order === "desc") {
      return articles.sort((a, b) => {
        return b.state.localeCompare(a.state);
      });
    }
  }
  return articles;
}

const addDescriptionStyle = css`
  flex: 1;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  font-size: 18px;
  :hover {
    color: #808080;
    border-color: #808080;
  }
`;

export default DashboardPage;
