import { MarkPointComponentOption } from "echarts";
import { LineChart, LineSeriesOption, PieChart } from "echarts/charts";
import {
  TitleComponent,
  // The component option types are defined with the ComponentOption suffix
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  // Built-in transform (filter, sort)
  TransformComponent,
  GridComponent,
  GridComponentOption,
  MarkPointComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

import React, { useEffect, useRef, useState } from "react";

type EChartsOption = echarts.ComposeOption<
  | LineSeriesOption
  | LegendComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | MarkPointComponentOption
>;

echarts.use([
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  LineChart,
  PieChart,
  LegendComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  GridComponent,
  MarkPointComponent,
]);

type BaseChartProps = {
  options: EChartsOption;
};

const Charts: React.FC<BaseChartProps | any> = ({ options }) => {
  const chartRef = useRef<HTMLInputElement>(null);
  const [chart, setChart] = useState<echarts.ECharts>();

  const handleResize = () => {
    chart?.resize();
  };

  const initChart = () => {
    if (chart) {
      window.removeEventListener("resize", handleResize);
      chart?.dispose();
    }

    const newChart = echarts?.init(chartRef?.current as HTMLElement);
    newChart.setOption(options, true);
    window.addEventListener("resize", handleResize);
    setChart(newChart);
  };

  useEffect(() => {
    if (options) {
      initChart();
    }
  }, [options]);

  return <div ref={chartRef} style={{ height: "100%", width: "100%" }} />;
};

export { Charts };
