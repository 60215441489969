/** @jsxImportSource @emotion/react */
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  css,
  Dialog,
  DialogActions,
  IconButton,
} from "@mui/material";
import { t } from "i18next";
import { useState, VFC } from "react";
import { Trans } from "react-i18next";
import { CountryPath } from "src/entities/CountryPath";
import getCountryName from "src/utils/getCountryName";
import Button from "../common/Button";

type ArticleState = "PUBLISHED" | "DRAFT";

interface Props {
  open: boolean;
  handleClose: () => void;
  createArticle: (state: ArticleState) => Promise<void>;
  country: CountryPath;
  handleBackDropOpen: () => void;
  is_subscripted: boolean;
}

const CreateArticleModal: VFC<Props> = (props: Props) => {
  const [isComfirm, setIsComfirm] = useState(false);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <div className="p-4">
        <div style={{ textAlign: "right" }}>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="text-base whitespace-pre-line text-black pt-4 pb-4">
          {props.country === "IDN" && !props.is_subscripted ? (
            t("create_press_release.modal.post.description_in_indonesia")
          ) : (
            <Trans>
              {t("create_press_release.modal.post.description", {
                country: getCountryName(props.country),
              })}
            </Trans>
          )}
          <div css={dialogCaution}>
            <Checkbox
              style={{ padding: 0 }}
              checked={isComfirm}
              size="small"
              onChange={(e) => {
                setIsComfirm(e.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span
              style={{
                color: "#rgba(45, 45, 45, 0.8)",
                fontSize: 14,
                marginLeft: 4,
              }}
            >
              {t("create_press_release.modal.post.confirm")}
            </span>
          </div>
        </div>
        <DialogActions>
          <Button
            text={t("create_press_release.modal.button.cancel")}
            onClick={props.handleClose}
          />
          <Button
            text={t("create_press_release.modal.button.post")}
            disabled={!isComfirm}
            onClick={() => {
              props.handleBackDropOpen();
              props.createArticle("PUBLISHED");
            }}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

const dialogCaution = css`
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 24px;
`;

export default CreateArticleModal;
