/** @jsxImportSource @emotion/react */
import { OutputData } from "@editorjs/editorjs";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Modal, css } from "@mui/material";
import EditorJSHtml from "editorjs-html";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountryPath } from "src/entities/CountryPath";
import { LANGUAGES } from "src/entities/Languages";
import { Template, TemplateVariables } from "src/entities/Template";
import { useFetch } from "src/hooks/useFetch";
import { youtubeParser } from "src/libs/editor/renderYoutube";
import { imageParser } from "src/utils/editorjs/imageParser";
import { getLanguage } from "src/utils/getLanguage";

interface Props {
  country: CountryPath;
  handleTitleChange: (title: string) => void;
  handleSummaryChange: (summary: string) => void;
  handleOutputBody: (outputBody: OutputData) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TemplateModal = (props: Props) => {
  /**
   * Modal parameters
   */
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.setOpen(false);
    setOpen(false);
  };

  /**
   * Selected parameters
   */
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    getLanguage(props.country)
  );
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateVariables>();

  /**
   * Get business categories
   */
  const {
    data: templates,
    refetch,
    isLoading,
  } = useFetch<Template[]>({
    url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/templates?language=${selectedLanguage}`,
    skip: selectedLanguage === undefined || selectedLanguage === "",
    onError: () => {
      console.error(t("create_press_release.content.failed_fetch_templates"));
    },
    onSuccess: (data) => {
      if (data.length > 0) {
        setSelectedTemplate(data[0].templates[0]);
      } else {
        setSelectedTemplate(undefined);
      }
    },
  });

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "80%",
            backgroundColor: "#fff",
            boxShadow: "24",
            borderRadius: "0.75rem",
          }}
        >
          <div className="flex rounded-l-xl h-full">
            <div className="bg-background rounded-l-xl z-10 min-w-fit overflow-scroll">
              <div className="p-2 mt-4">
                <select
                  className="w-full p-2 rounded-sm border hover:cursor-pointer"
                  onChange={(e) => {
                    setSelectedLanguage(e.target.value);
                  }}
                  value={selectedLanguage}
                >
                  {LANGUAGES.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="p-8" style={{ maxWidth: 300 }}>
                {isLoading && (
                  <p>{t("create_press_release.content.loading")}</p>
                )}
                {templates?.map(
                  (templateCategory) =>
                    templateCategory.templates.length > 0 && (
                      <>
                        <p
                          key={templateCategory.id}
                          className="mt-3 font-bold text-gray-400 whitespace-break-spaces text-left leading-4"
                        >
                          {templateCategory.template_category_name}
                        </p>
                        {templateCategory.templates.map((template) => (
                          <p key={template.id}>
                            <button
                              type="button"
                              className={`mt-3 whitespace-break-spaces text-left leading-4 hover:cursor-pointer hover:underline ${
                                template.id === selectedTemplate?.id &&
                                "font-bold hover:cursor-default hover:no-underline"
                              }`}
                              onClick={() => {
                                setSelectedTemplate(template);
                              }}
                            >
                              {template.name}
                            </button>
                          </p>
                        ))}
                      </>
                    )
                )}
              </div>
            </div>
            <div className="w-full h-full overflow-scroll">
              <div className="p-8 w-full">
                {isLoading ? (
                  <p>{t("create_press_release.content.loading")}</p>
                ) : (
                  <>
                    <div className="w-full flex justify-between mb-4">
                      <div>
                        <div className="font-bold text-lg">
                          {selectedTemplate?.name}
                        </div>
                      </div>
                      <button type="button" onClick={handleClose}>
                        <CloseIcon />
                      </button>
                    </div>
                    {/* Title */}
                    <div className="mb-4">
                      <div>
                        <div className="text-gray-400">
                          {t("create_press_release.content.title")}
                        </div>
                        <h3 className="font-bold">{selectedTemplate?.title}</h3>
                      </div>
                    </div>
                    {/* Summary */}
                    <div className="mb-4">
                      <div>
                        <div className="text-gray-400">
                          {t("create_press_release.content.summary")}
                        </div>
                        <div className="">{selectedTemplate?.summary}</div>
                      </div>
                    </div>
                    {/* Body */}
                    <div className="pb-24">
                      <div>
                        <div className="text-gray-400">
                          {t("create_press_release.content.body")}
                        </div>
                        {selectedTemplate?.body && (
                          <ArticleViewer
                            body={
                              JSON.parse(
                                selectedTemplate?.body
                              ) as unknown as OutputData
                            }
                          />
                        )}
                      </div>
                    </div>
                    {/* Footer */}
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: "#fff",
                      }}
                      className="p-8 w-full flex justify-end rounded-xl"
                    >
                      <button
                        className="h-12 flex justify-center items-center content-center px-4 py-2 rounded-md border-solid border-2 border-primary bg-white hover:border-button-hover hover:text-button-hover disabled:border-opacity-50 disabled:hover:border-primary disabled:text-primary disabled:hover:border-opacity-50 disabled:text-opacity-50 disabled:hover:text-opacity-50 text-primary"
                        style={{
                          border: "1px solid #0045FF",
                          width: 250,
                          fontSize: 16,
                        }}
                        type="button"
                        onClick={() => {
                          props.handleTitleChange(
                            selectedTemplate?.title || ""
                          );
                          props.handleSummaryChange(
                            selectedTemplate?.summary || ""
                          );
                          props.handleOutputBody(
                            JSON.parse(selectedTemplate?.body) as OutputData
                          );
                          props.setOpen(false);
                          handleClose();
                        }}
                      >
                        {t(
                          "create_press_release.button.create_from_this_template"
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <button
        className="h-8 mr-4 flex justify-center items-center content-center px-4 py-2 rounded-md border-solid border-2 border-primary bg-white hover:border-button-hover hover:text-button-hover disabled:border-opacity-50 disabled:hover:border-primary disabled:text-primary disabled:hover:border-opacity-50 disabled:text-opacity-50 disabled:hover:text-opacity-50 text-primary hover:underline"
        style={{
          border: "1px solid #0045FF",
          width: 180,
          fontSize: 14,
        }}
        type="button"
        onClick={() => {
          handleClickOpen();
          props.setOpen(true);
        }}
      >
        {t("create_press_release.button.create_from_a_template")}
      </button>
    </>
  );
};

type ArticleViewerProps = {
  body: OutputData;
};

const ArticleViewer = ({ body }: ArticleViewerProps): JSX.Element => {
  if (body === undefined) {
    return <div />;
  }

  const parser = EditorJSHtml({
    youtubeEmbed: youtubeParser,
    image: imageParser,
  });
  const mainText = parser.parse(body).reduce((x, y) => `${x}${y}`);

  return (
    // eslint-disable-next-line react/no-danger
    <div css={detailBody} dangerouslySetInnerHTML={{ __html: mainText }} />
  );
};

const detailBody = css`
  color: #2d2d2d;
  h1 {
    font-size: xx-large;
    font-weight: bold;
  }
  h2 {
    font-size: 26px;
    font-weight: bold;
    padding-top: 16px;
    padding-bottom: 4px;
    line-height: 1.5;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 2px;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  figure {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  figcaption {
    font-size: 12px;
    margin-top: 8px;
    color: #707070;
    font-style: italic;
  }
  img {
    object-fit: cover;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    list-style: disc;
  }
  p {
    letter-spacing: 0.01rem;
    margin-bottom: 16px;
    overflow-wrap: anywhere;
    color: #707070;
    line-height: 1.7;
  }
  a {
    text-decoration: underline;
    color: #0045ff;
  }
`;
