export type AvailLanguage = "en" | "id" | "vn" | "th";

const getBrowserLanguage = (): AvailLanguage => {
  // localStorageに言語が保存されている場合はそれを返す
  const storedLanguage = localStorage.getItem("vritimes_language");
  if (storedLanguage) return storedLanguage as AvailLanguage;

  const browserLanguage = navigator.language;
  console.log(browserLanguage);
  if (browserLanguage.startsWith("th")) return "th";
  if (browserLanguage.startsWith("vi")) return "vn";
  if (browserLanguage.startsWith("id")) return "id";
  return "en";
};

export default getBrowserLanguage;
