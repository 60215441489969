import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from "axios";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "./useAuth";

interface Params {
  [key: string]: any;
}

interface Args<T> {
  url?: string;
  params?: Params;
  body?: Params;
  onStart?: () => void;
  onEnd?: () => void;
  onSuccess?: GenericFunction;
  onError?: (err: AxiosError) => void;
}

interface PostRequest<T> {
  method?: Method;
  url?: string;
  params?: Params;
  body?: Params;
  headers?: AxiosRequestHeaders;
  onStart?: () => void;
  onEnd?: () => void;
  onSuccess?: GenericFunction;
  onError?: (err: AxiosError) => void;
}

type Post<T> = ({ params, url }: Args<T>) => Promise<void>;
type GenericFunction = <T>(data?: T) => void;

interface PutResponse<T> {
  put: Post<T>;
  isLoading: boolean;
}

export function usePut<T>({
  method = "put",
  url,
  params,
  headers,
  onStart,
  onEnd,
  onSuccess,
  onError,
}: PostRequest<T>): PutResponse<T> {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const methodInternal = useMemo(() => method, [method]);
  const urlInternal = useMemo(() => url, [url]);
  const paramsInternal = useMemo(() => params, [params]);
  const { authInfo } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const put = useCallback<Post<T>>(
    // eslint-disable-next-line no-shadow
    async <T>(args: Args<T>) => {
      const reqUrl = args.url ?? urlInternal ?? "";
      if (!reqUrl) {
        return;
      }
      if (!methodInternal) {
        return;
      }

      if (args.onStart) {
        args.onStart();
      } else onStart?.();

      try {
        setLoading(true);
        const res: AxiosResponse<T> = await axios.request({
          method: method ?? methodInternal,
          url: reqUrl,
          data: args.params ?? paramsInternal ?? undefined,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${authInfo.accessToken}`,
            ...headers,
          },
        });
        if (args.onSuccess) {
          args.onSuccess(res.data);
        } else onSuccess?.(res.data);
      } catch (err) {
        if (err.message !== "") {
          enqueueSnackbar(err.message, { variant: 'error' });
        } else {
          enqueueSnackbar(t("general.service_failed"), { variant: "error" });
        }
        if (args.onError) {
          args.onError(err as AxiosError);
        } else onError?.(err as AxiosError);
      } finally {
        setLoading(false);
        if (args.onEnd) {
          args.onEnd();
        } else onEnd?.();
      }
    },
    [headers, methodInternal, paramsInternal, urlInternal]
  );

  const clear = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => () => clear(), [clear]);

  return {
    put,
    isLoading,
  };
}
