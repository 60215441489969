/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Backdrop,
  css,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { NavigateFunction, useNavigate } from "react-router";
import { CountryPath } from "src/entities/CountryPath";
import { GetArticlePublicationsResponse } from "src/entities/GetArticlePublicationsResponse";
import { GetArticlePublicationsResponseData } from "src/entities/GetArticlePublicationsResponseData";
import { UserInfoResponse } from "src/entities/UserInfoResponse";
import { useDelete } from "src/hooks/useDelete";
import { useFetch } from "src/hooks/useFetch";
import getCountryCode from "src/utils/getContryCode";
import Button from "../components/common/Button";
import { StyledLightTooltip } from "../components/common/StyledTooltip";
import PageContentsWrapper from "../components/page/PageContentsWrapper";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";
import StyledTable from "../components/table/StyledTable";
import StyledTableBody from "../components/table/StyledTableBody";
import StyledTableCell from "../components/table/StyledTableCell";
import StyledTableHeader from "../components/table/StyledTableHeader";
import StyledTableHeaderRow from "../components/table/StyledTableHeaderRow";
import StyledTableRow from "../components/table/StyledTableRow";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
  country: CountryPath;
  userInfo?: UserInfoResponse;
  isUserInfoLoading: boolean;
}

/**
 * Sort by
 */
type SortBy = "title" | "article_publications" | "media_name" | "published_at";
/**
 * Sort order
 */
type SortOrder = "asc" | "desc";

const PotentialPublicationsPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState<string>("");
  const [selectedArticlePublication, setSelectedArticlePublication] =
    useState<string>("");
  const [sortBy, setSortBy] = useState<SortBy>("title");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleBackDropOpen = () => {
    setBackdropOpen(true);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  useEffect(() => {
    if (props.userInfo) {
      refetchPublications();
    }
  }, [props.country]);

  const {
    data: articlePublications,
    isLoading: isArticlePublications,
    refetch: refetchPublications,
  } = useFetch<GetArticlePublicationsResponse>({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articlePublications/${props?.userInfo?.publisher_id}`,
    headers: {
      country: props.country,
    },
    onError: () => {
      enqueueSnackbar(t("error.login_again"), {
        variant: "error",
      });
      props.signout(navigate);
    },
  });

  const { exeDelete } = useDelete({
    url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/articlePublications/${props?.userInfo?.publisher_id}/${selectedArticlePublication}`,
    headers: {
      country: props.country,
    },
    onSuccess: () => {
      handleDeleteClose();
      refetchPublications();
      enqueueSnackbar(t("potential_publications.delete.success"), {
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar(t("potential_publications.delete.fail"), {
        variant: "error",
      });
    },
    onStart: handleBackDropOpen,
    onEnd: () => {
      handleDeleteClose();
      handleBackdropClose();
    },
  });

  const sortedArticlePublications =
    articlePublications &&
    sortArticles(articlePublications?.data, sortBy, sortOrder);

  return (
    <PageWrapper>
      <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(
              `/article/${props?.userInfo?.publisher_id}/${selectedSlug}/DISTRIBUTION`
            );
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <AiOutlineEdit size="24px" />
          </ListItemIcon>
          <ListItemText sx={{ minWidth: 80 }}>
            {t("dashboard.table.actions.edit")}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <div className="p-4">
          <div style={{ textAlign: "right" }}>
            <IconButton onClick={handleDeleteClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <div className="text-base text-black pt-4 pb-4">
              {t("potential_publications.table.delete")}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              text={t("dashboard.modal.button.cancel")}
              onClick={handleDeleteClose}
            />
            <Button
              text={t("potential_publications.modal.button.delete")}
              onClick={() => {
                exeDelete({});
              }}
            />
          </DialogActions>
        </div>
      </Dialog>
      <div className="flex items-center">
        <PageHeader title={t("potential_publications.page_header")} />
        <div className="ml-4 pt-6">
          <div>{t("potential_publications.tips1")}</div>
          <div>{t("potential_publications.tips2")}</div>
        </div>
      </div>
      <PageContentsWrapper>
        {props.isUserInfoLoading || isArticlePublications ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : sortedArticlePublications &&
          sortedArticlePublications.length > 0 ? (
          <StyledTable>
            <StyledTableHeader>
              <StyledTableHeaderRow>
                <th className="px-4 w-5% border-r" align="center">
                  {" "}
                </th>
                <th
                  onClick={() => {
                    setSortBy("title");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-25% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("potential_publications.table.title")}
                  {sortBy === "title" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => {
                    setSortBy("article_publications");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-30% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("potential_publications.table.potential_publications")}
                  {sortBy === "article_publications" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  className="px-4 w-15% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("potential_publications.table.media_name")}
                </th>
                <th
                  onClick={() => {
                    setSortBy("published_at");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-15% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("potential_publications.table.post_date")}
                  {sortBy === "published_at" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  className="px-4 w-10% border-r hover:cursor-pointer whitespace-pre-line"
                  align="left"
                >
                  {t("potential_publications.table.delete")}
                </th>
              </StyledTableHeaderRow>
            </StyledTableHeader>
            <StyledTableBody>
              {sortedArticlePublications?.map((article, index: number) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    {article.article_publication?.length > 0 ? (
                      article.article_publication?.map(
                        (publication, subIndex) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <StyledTableRow key={`${index}-${subIndex}`}>
                            {subIndex === 0 ? (
                              <StyledTableCell
                                button
                                rowSpan={article.article_publication.length}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    setSelectedSlug(article.slug);
                                    handleMenuClick(e);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </StyledTableCell>
                            ) : null}
                            {subIndex === 0 && (
                              <StyledTableCell
                                rowSpan={article.article_publication.length}
                              >
                                <img
                                  src={article.thumbnail_image_url}
                                  alt="preview"
                                  style={{ width: "15%", float: "left" }}
                                />
                                <div
                                  style={{
                                    width: "85%",
                                    float: "left",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <a
                                    href={`${
                                      process.env.REACT_APP_PUBLISHER_DOMAIN
                                    }/${getCountryCode(
                                      props.country
                                    )}/articles/${article.publisher.id}/${
                                      article.slug
                                    }`}
                                    className="flex items-center content-center px-4 rounded-md bg-white text-primary"
                                    css={title}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {article.title}
                                  </a>
                                  <div>
                                    {t("potential_publications.publish_at")}
                                    {DateTime.fromISO(
                                      article.published_at
                                    ).toFormat("MMMM dd, yyyy")}
                                  </div>
                                </div>
                              </StyledTableCell>
                            )}
                            <StyledTableCell>
                              <a
                                href={`${publication.link}`}
                                className="flex items-center content-center px-4 rounded-md bg-white text-primary"
                                css={title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {publication.title}
                                <OpenInNewIcon style={{ marginLeft: 8 }} />
                              </a>
                              <div>
                                <img
                                  src={article.thumbnail_image_url}
                                  alt="preview"
                                  style={{ width: "10%", float: "left" }}
                                />
                                <div css={snippet}>{publication.snippet}</div>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <p
                                style={{
                                  fontSize: 14,
                                  overflowWrap: "break-word",
                                }}
                              >
                                {new URL(publication.link).host}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell>
                              <p style={{ fontSize: 14 }}>
                                {DateTime.fromISO(
                                  article.published_at
                                ).toFormat("MMMM dd, yyyy")}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip
                                title={t(
                                  "potential_publications.modal.button.delete"
                                )}
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="p-1 rounded-lg	hover:bg-slate-50"
                                  onClick={() => {
                                    setSelectedArticlePublication(
                                      publication.id
                                    );
                                    handleClickDeleteOpen();
                                  }}
                                >
                                  <AiOutlineDelete fill="black" size="24px" />
                                </button>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )
                    ) : (
                      // eslint-disable-next-line react/no-array-index-key
                      <StyledTableRow key={`${index}`}>
                        <StyledTableCell button>
                          <IconButton
                            onClick={(e) => {
                              setSelectedSlug(article.slug);
                              handleMenuClick(e);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>
                          <img
                            src={article.thumbnail_image_url}
                            alt="preview"
                            style={{ width: "15%", float: "left" }}
                          />
                          <div
                            style={{
                              width: "85%",
                              float: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            <a
                              href={`${
                                process.env.REACT_APP_PUBLISHER_DOMAIN
                              }/${getCountryCode(props.country)}/articles/${
                                article.publisher.id
                              }/${article.slug}`}
                              className="flex items-center content-center px-4 rounded-md bg-white text-primary"
                              css={title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {article.title}
                            </a>
                            <div>
                              {t("potential_publications.publish_at")}
                              {DateTime.fromISO(article.published_at).toFormat(
                                "MMMM dd, yyyy"
                              )}
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {article.search_words?.length > 0 ? (
                            <ProcessingTooltip t={t} />
                          ) : (
                            <div css={noPublication}>
                              {t("potential_publications.word_not_set")}
                              <a
                                href="#"
                                className="flex items-center content-center px-4 rounded-md bg-white text-primary"
                                css={title}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(event) => {
                                  event.preventDefault();
                                  navigate(
                                    `/article/${props?.userInfo?.publisher_id}/${article.slug}/DISTRIBUTION`
                                  );
                                }}
                              >
                                {">>"} {t("potential_publications.set_word")}
                              </a>
                            </div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {article.search_words?.length > 0 ? (
                            <ProcessingTooltip t={t} />
                          ) : (
                            <div css={noPublication}>-</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {article.search_words?.length > 0 ? (
                            <ProcessingTooltip t={t} />
                          ) : (
                            <div css={noPublication}>-</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {article.search_words?.length > 0 ? (
                            <ProcessingTooltip t={t} />
                          ) : (
                            <div css={noPublication}>-</div>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </StyledTableBody>
          </StyledTable>
        ) : sortedArticlePublications ? (
          <div
            css={addDescriptionStyle}
            className="mb-2 font-semibold whitespace-pre-line"
          >
            {t("potential_publications.add_press_release")}
          </div>
        ) : (
          <div>
            <div className="mb-2 font-semibold">{t("error.login_again")}</div>
          </div>
        )}
      </PageContentsWrapper>
    </PageWrapper>
  );
};

const ProcessingTooltip = (props: { t: any }) => (
  <StyledLightTooltip
    title={props.t("potential_publications.styled.light.tooltip")}
    placement="left"
    arrow
  >
    <p style={{ textAlign: "right" }}>{props.t("general.processing")}</p>
  </StyledLightTooltip>
);

function sortArticles(
  articles: GetArticlePublicationsResponseData[],
  sortBy: SortBy,
  order: SortOrder
): GetArticlePublicationsResponseData[] {
  if (sortBy === "published_at") {
    if (order === "asc") {
      return articles.sort((a, b) => {
        return (
          DateTime.fromISO(a.published_at).toMillis() -
          DateTime.fromISO(b.published_at).toMillis()
        );
      });
    }
    if (order === "desc") {
      return articles.sort((a, b) => {
        return (
          DateTime.fromISO(b.published_at).toMillis() -
          DateTime.fromISO(a.published_at).toMillis()
        );
      });
    }
  } else if (sortBy === "title" || sortBy === "article_publications") {
    if (order === "asc") {
      return articles.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    }
    if (order === "desc") {
      return articles.sort((a, b) => {
        return b.title.localeCompare(a.title);
      });
    }
  }
  return articles;
}

const addDescriptionStyle = css`
  flex: 1;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  font-size: 18px;
`;

const title = css`
  border: none;
  width: 100%;
  font-size: 16px;
  padding-left: 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const snippet = css`
  overflow-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const noPublication = css`
  text-align: center;
  font-size: large;
  font-weight: 600;
`;

export default PotentialPublicationsPage;
