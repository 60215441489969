import { format, isMatch, parse } from "date-fns";
import { EChartsOption } from "echarts";
import { useMemo, VFC } from "react";
import { AnalyticsGraphResponse } from "src/entities/AnalyticsGraphResponse";
import { DateSelectionPeriod } from "src/view/pages/AnalyticsPage";
import { Charts } from "../../common/Chart";

interface Props {
  summaryData: AnalyticsGraphResponse;
  selectionType: DateSelectionPeriod;
}

const LineChart: VFC<Props> = (props: Props) => {
  const lineChartOption: EChartsOption = useMemo(
    () => ({
      legend: {
        bottom: "0%",
        left: "right",
        width: "100%",
        // icon: "rect",
        itemHeight: 2,
        itemWidth: 30,
        orient: "horizontal",
        type: 'scroll',
        formatter: (name: string) => {
          return `{a|${name}}`;
        },
        textStyle: {
          backgroundColor: "transparent",
          fontSize: 12,
          rich: {
            a: {
              width: 130,
            },
          },
        },
      },
      grid: {
        left: "0%",
        top: 32,
        width: "100%",
        height: "75%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        axisLabel: {
          show: true,
          formatter: (value: string, index: number): string => {
            return props.selectionType === "period"
              ? (index + 1).toString()
              : isMatch(value, "yyyy-MM-dd")
              ? format(parse(value, "yyyy-MM-dd", new Date()), "MMM dd yyyy")
              : value;
          },
        },
        axisTick: {
          show: false,
        },
        data: props.summaryData.statistics.labels?.map((label) =>
          props.selectionType === "period"
            ? `Day ${label}`
            : isMatch(label, "yyyy-MM-dd")
            ? format(parse(label, "yyyy-MM-dd", new Date()), "MMM dd yyyy")
            : label
        ),
      },
      tooltip: {
        trigger: "axis",
      },
      yAxis: {
        type: "value",
        nameTextStyle: {
          color: "#f7f8f9",
        },
        axisLine: {
          show: true,
        },
      },
      color: [
        "#332288",
        "#88CCEE",
        "#44AA99",
        "#117733",
        "#999933",
        "#DDCC77",
        "#CC6677",
        "#882255",
        "#AA4499",
      ],
      series: [
        props.selectionType === "period"
          ? {
              name: "Average",
              type: "line",
              data: props.summaryData.statistics.average_page_views,
              symbol: "none",
              lineStyle: {
                width: 3.5,
                color: "#ababab",
                type: "dashed",
              },
              // markPoint: {
              //   data: [{ type: "max", name: "Max" }],
              //   symbolSize: "none",
              //   label: {
              //     color: "#668FFF",
              //     fontSize: 16,
              //     fontWeight: 700,
              //     offset: [0, -10],
              //   },
              // },
            }
          : undefined,
        ...[
          ...(props.summaryData.articles_data.map((articleData) => ({
            data: articleData.page_views,
            type: "line",
            symbol: "none",
            name: articleData.title,
            lineStyle: {
              width: 3.5,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          })) as any),
        ],
      ],
    }),
    [props.summaryData]
  );
  return <Charts options={lineChartOption} />;
};
export default LineChart;
