import { styled, Tab } from "@mui/material";

interface StyledTabProps {
  label: string;
  value: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(16),
  color: "#A0A3BD",
  "&.Mui-selected": {
    color: "#2D2D2D",
    fontWeight: 700,
  },
  "&.MuiTab-root": {
    height: "36px",
    minHeight: "36px",
  },
  padding: "8px 12px",
}));

export default StyledTab;
