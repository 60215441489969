import { VFC } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  className?: string;
}

const TabPanel: VFC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={`${value === index ? "" : "hidden"} overflow-auto`}
      {...other}
    >
      <div className={` ${className}`}>{value === index && children}</div>
    </div>
  );
};

export default TabPanel;
